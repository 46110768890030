import moment from "moment";
import { useNavigate } from "react-router-dom";
import ProductImg from "../../../../../assets/images/Product.png";
import { useSubscription } from "../../../../../SubscriptionContext";
import DeleteRed from "../../../../Icons/DeleteRed";
import NoContentCard from "../../../../molecules/NoContentCard";
import "../saved_list.scss";

const SavedProducts = ({ removeIcon, data, handleDelete, isSearched }: any) => {
  const navigate = useNavigate();
  const { isSubscriptionExpired, handleUserClick } = useSubscription();

  return (
    <div className="saved-data-container">
      {data?.length > 0 ? (
        <>
          {data?.map((item: any) => (
            <div className="saved-item-container">
              <div
                className="saved-item cursor-pointer"
                key={item.product_id}
                onClick={(e) => {
                  isSubscriptionExpired
                    ? handleUserClick(e)
                    : window.open(
                        `/dashboard/product/${item.product_id}`,
                        "_blank"
                      );
                }}
              >
                <div>
                  <div className="d-flex align-items-start gap-4">
                    <img src={ProductImg} alt="" className="product-image" />
                    <div>
                      <h4 className="h4-i semi-light mb-1">{item?.name}</h4>
                      <p className="p mb-1">{item.product_category}</p>
                      <p className="p mb-1">
                        {moment.utc(item.updated_date).format("MMM D, YYYY")}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <button
                className={
                  !removeIcon ? "btn btn-outline-danger" : "manage-icon"
                }
                onClick={(e) => {
                  isSubscriptionExpired
                    ? handleUserClick(e)
                    : handleDelete(item.saved_list);
                }}
              >
                {!removeIcon ? (
                  "Remove"
                ) : (
                  <i className="d-flex">
                    <DeleteRed className="Saved-delete-icon-container" />
                  </i>
                )}
              </button>
            </div>
          ))}
        </>
      ) : (
        <div className="non-saved">
          {isSearched ? (
            <NoContentCard title="Sorry! No Result Found" type="Search" />
          ) : (
            <NoContentCard title="No Product Saved Yet !" type="" />
          )}
        </div>
      )}
    </div>
  );
};

export default SavedProducts;
