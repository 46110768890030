import InputLayout from "../../../../atoms/InputLayout";
import PageCard from "../../../../atoms/PageCard";

const InvestmentPreference = ({ data }: any) => {
  const mapProductStrategyNames = (investmentItem: any) => {
    const data =
      investmentItem?.product_startegy?.map((strategy: any) => strategy.name) ||
      [];
    return data.join(", ");
  };

  const mapProductSectore = (investmentItem: any) => {
    const data =
      investmentItem?.product_sectors?.map((strategy: any) => strategy.name) ||
      [];
    return data.join(", ");
  };

  const mapProductGeography = (investmentItem: any) => {
    const data =
      investmentItem?.product_geography?.map(
        (strategy: any) => strategy.name
      ) || [];
    return data.join(", ");
  };

  return (
    <>
      <h3 className="h3-j mb-3">
        {/* {data?.investment_preference?.length}{" "} */}
        {data?.investment_preference?.length <= 1
          ? "Investment Preference"
          : "Invetsment Preferences"}
      </h3>

      {data?.investment_preference?.length > 0 &&
        data?.investment_preference?.map((item: any) => (
          <PageCard
            customStyles={{ backgroundColor: "#F9F9F9" }}
            classes="p-4 mb-4 investment-preferences-container"
          >
            {/* <div className="row"> */}
            <InputLayout>
              <div className="p-dark-product-company">Product Structure</div>
              <div className="p-light-small-product-company">
                {item?.product_structure?.name || "--"}
              </div>
            </InputLayout>

            <InputLayout>
              <div className="p-dark-product-company">Product Type</div>
              <div className="p-light-small-product-company">
                {item?.product_type?.name || "--"}
              </div>
            </InputLayout>

            {item?.product_startegy?.length > 0 && (
              <InputLayout>
                <div className="p-dark-product-company">Product Strategy</div>
                <div className="p-light-small-product-company">
                  {mapProductStrategyNames(item)}
                </div>
              </InputLayout>
            )}
            {/* </div> */}

            {/* <div className="row mt-3"> */}
            {item?.product_sectors?.length > 0 && (
              <InputLayout>
                <div className="p-dark-product-company">Product Sector</div>
                <div className="p-light-small-product-company">
                  {mapProductSectore(item)}
                </div>
              </InputLayout>
            )}
            {item?.product_geography?.length > 0 && (
              <InputLayout>
                <div className="p-dark-product-company">Product Geography</div>
                <div className="p-light-small-product-company">
                  {mapProductGeography(item)}
                </div>
              </InputLayout>
            )}
            {(item?.min_aum || item?.max_aum) && (
              <InputLayout>
                <div className="p-dark-product-company">AUM Threshold</div>
                <p className="p-light-small-product-company">
                  {" "}
                  {item?.min_aum && "$"}&nbsp;{item?.min_aum}&nbsp;
                  {item?.min_aum && item?.max_aum && "-"}&nbsp;
                  {item?.max_aum && "$"}&nbsp;{item?.max_aum}
                </p>
              </InputLayout>
            )}
            {/* </div> */}

            {
              (item?.min_track_record || item?.max_track_record) && (
                // <div className="row mt-2">
                // <>
                <InputLayout>
                  <div className="p-dark-product-company">
                    {" "}
                    Track Length Record
                  </div>
                  <p className="p-light-small-product-company">
                    {item?.min_track_record}&nbsp;
                    {item?.min_track_record && item?.max_track_record && "-"}
                    &nbsp;
                    {item?.max_track_record} Years
                  </p>
                </InputLayout>
              )

              // {/* <InputLayout></InputLayout>
              // <InputLayout></InputLayout> */}
              // </div>
            }
          </PageCard>
        ))}
    </>
  );
};

export default InvestmentPreference;
