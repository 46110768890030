import moment from "moment";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useScreenWidth from "../../../../../hooks/useScreenwidth";
import ImageIcon from "../../../../atoms/ImageIcon";
import InputLayout from "../../../../atoms/InputLayout";
import "./component.scss";

const CompanyOverview = ({ data, changeTab }: any) => {
  const [expanded, setExpanded] = useState(false);
  const { id } = useParams();
  const screenWidth = useScreenWidth();
  const navigate = useNavigate();
  const toggleExpand = () => {
    setExpanded(!expanded);
  };
  const normalData =
    data?.company_overview?.overview === "<p><br></p>" ||
    !data?.company_overview?.overview
      ? "__"
      : data?.company_overview?.overview;
  const displayText = expanded
    ? normalData || "__"
    : screenWidth <= 480
    ? (normalData || "__")?.substring(0, 400)
    : (normalData || "__")?.substring(0, 700); // Display first 100 characters

  const typeNames = data?.product
    ?.map((product: any) => product?.type_name)
    .filter((typeName: any) => typeName !== null && typeName !== undefined);

  const socialChannels = data?.company_overview?.social_channel;
  const products = data?.product;

  const handleNavigation = (product: any) => {
    // if () {

    // } else {

    if (id && !product.allow_access) {
      changeTab("Products");
    } else {
      navigate(`/dashboard/product/${product.product_id}`);
    }

    // }
  };
  const tags = data?.company_overview?.tag;

  return (
    <div className="company-overview-container">
      {data?.company_overview?.overview && (
        <>
          {normalData !== "__" && (
            <div className="aboutCompanyWrap">
              <h3 className="h4-j mb-small">About Company</h3>
              {/* <p className="p">{displayText}</p> */}
              <div className="quill quill-change">
                <div
                  className="ql-container ql-snow ql-no-border"
                  style={{ background: "white" }}
                >
                  <div
                    className="ql-editor blog-content"
                    style={{ padding: "0 !important" }}
                    dangerouslySetInnerHTML={{
                      __html: displayText,
                    }}
                  ></div>
                </div>
              </div>
              {normalData &&
                (screenWidth <= 480
                  ? normalData?.length > 400
                  : normalData?.length > 700) && (
                  <span onClick={toggleExpand} className="view-more">
                    {expanded ? "View less" : "View more"}
                  </span>
                )}

              <hr className="mt-medium" />
            </div>
          )}
        </>
      )}

      {(data?.company_overview?.invester_type?.length ||
        typeNames?.length ||
        (tags?.length > 0 && tags?.[0] !== null) ||
        (!data?.company_overview?.is_not_applicable &&
          data?.company_overview?.aum_amount)) && (
        <>
          <div className={normalData !== "__" ? "mt-large" : ""}>
            <h4 className="h4-j mb-3">Type & Size</h4>
            <div className="row g-3  Type-size-overview-container">
              {data?.company_overview?.invester_type?.length > 0 && (
                <InputLayout classes="col-lg-6">
                  <label htmlFor="Type">Type</label>
                  <p className="p">
                    {data?.company_overview?.invester_type &&
                      [data?.company_overview?.invester_type].join(", ")}
                  </p>
                </InputLayout>
              )}

              {typeNames?.length > 0 && (
                <InputLayout classes="col-lg-6">
                  <label htmlFor="Product Type">Product Type</label>
                  <p className="p">{typeNames && typeNames?.join(", ")}</p>
                </InputLayout>
              )}

              {!data?.company_overview?.is_not_applicable &&
                data?.company_overview?.aum_amount && (
                  <InputLayout classes="col-lg-6">
                    <label htmlFor="AUM">AUM (USD Millions)</label>
                    <p className="p mb-0">
                      {data?.company_overview?.aum_amount && "$"}
                      {data?.company_overview?.aum_amount.toLocaleString(
                        "en-US"
                      )}{" "}
                      (as of{" "}
                      {moment
                        .utc(data?.company_overview?.aum_month_year)
                        .format("MMM YYYY") || "--"}
                      )
                    </p>
                  </InputLayout>
                )}

              {tags?.length > 0 && tags?.[0] !== null && (
                <InputLayout classes="col-lg-6">
                  <label htmlFor="Tags">Tag</label>
                  <p className="p">
                    {data?.company_overview?.tag &&
                      data?.company_overview?.tag?.join(", ")}
                  </p>
                </InputLayout>
              )}
            </div>
          </div>

          <hr className="mt-medium" />
        </>
      )}

      {/* Products */}
      {products?.length > 0 && (
        <div className="mt-large">
          <h4 className="h4-j mb-small">Products</h4>
          <div className="row">
            {products?.map((product: any) => (
              <InputLayout classes="col-sm-6">
                <label
                  htmlFor=""
                  className="cursor-pointer "
                  onClick={() => handleNavigation(product)}
                >
                  {product?.type_name}
                </label>
                <p
                  className="p cursor-pointer product-link"
                  onClick={() => handleNavigation(product)}
                >
                  {product?.name}{" "}
                </p>
              </InputLayout>
            ))}
          </div>
        </div>
      )}

      {products?.length > 0 && <hr className="mt-small" />}

      {/* Contact Details */}
      <div className="mt-large">
        <h4 className="h4-j mb-3">Contact Details</h4>

        {/* {data?.company_overview?.website && data?.company_overview?.email && ( */}
        <div className="row g-3">
          {data?.company_overview?.website && (
            <InputLayout classes="col-sm-6">
              <>
                <label htmlFor="Website">Website</label>
                <p className="p">{data?.company_overview?.website || "--"} </p>
              </>
            </InputLayout>
          )}

          {data?.company_overview?.email && (
            <InputLayout classes="col-sm-6">
              <>
                <label htmlFor="Email">Email</label>
                <p className="p">
                  {data?.company_overview?.email ||
                  data?.company_overview?.email !== "NULL"
                    ? data?.company_overview?.email
                    : "--"}
                </p>
              </>
            </InputLayout>
          )}

          {data?.company_overview?.country_code &&
            data?.company_overview?.phone && (
              <InputLayout classes="col-sm-6">
                <label htmlFor="Phone">Phone</label>
                <p className="p">
                  {data?.company_overview?.country_code}{" "}
                  {data?.company_overview?.phone}
                </p>
              </InputLayout>
            )}

          {data?.company_overview?.founded_in && (
            <InputLayout classes="col-sm-6">
              <label htmlFor="Founded In">Founded In</label>
              <p className="p">
                {" "}
                {(data?.company_overview?.founded_in &&
                  moment
                    .utc(data?.company_overview?.founded_in)
                    .format("MMM YYYY")) ||
                  "--"}
              </p>
            </InputLayout>
          )}

          <InputLayout classes="col-sm-6">
            <label htmlFor="">Last Updated</label>
            <p className="p">
              {moment
                .utc(data?.company_overview?.last_updated)
                .format("MMM D, YYYY") || "--"}
            </p>
          </InputLayout>
        </div>
        {/* )} */}
      </div>

      <hr className="mt-small mb-medium" />

      {/* Social Channels */}
      {socialChannels?.length > 0 && (
        <div className="mt-large">
          <h4 className="h4-j mb-3">Social Channel</h4>
          <div className="social-channels">
            {socialChannels &&
              socialChannels?.length > 0 &&
              socialChannels?.map((social: any) => (
                <ImageIcon type={social?.channel_type} link={social?.link} />
              ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default CompanyOverview;
