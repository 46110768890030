import { useState } from "react";
import Image from "../../../assets/images/faq2.png";
import BannerImage from "../../atoms/BannerImage";
import ContentContainer from "../../atoms/ContentContainer";
import Heading from "../../atoms/Heading";
import Search from "../../atoms/Search";
// import Footer from "../../molecules/Footer";
// import Navbar from "../../molecules/Navbar";
import FaqTemplate from "../../templates/FaqTemplate";

type Props = {};

const FAQ = (props: Props) => {
  // State to store the latest search value
  const [searchedValue, setSearchedValue] = useState<string>("");

  // Function to handle search emit
  const handleSearchEmit = (searchValue: string) => {
    // Update the state with the latest search value
    setSearchedValue(searchValue);
  };

  return (
    <>
      {/* <Navbar sideBarRequired={false} /> */}
      <ContentContainer id="FAQ-Container">
        <Heading title="Frequently Asked Questions" type="main">
          {/* Pass the onSearchEmit handler to the Search component */}
          <Search onSearchEmit={handleSearchEmit} variant="search_lg" />
        </Heading>
        <BannerImage altText="text" imageUrl={Image} />
        <div className="py-5">
          {/* Pass the searchedValue as a prop to FaqTemplate */}
          <FaqTemplate
            type="all"
            color="#ffffff"
            searchedValue={searchedValue}
          />
        </div>
      </ContentContainer>
      {/* <Footer /> */}
    </>
  );
};

export default FAQ;
