import { Box } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import useScreenWidth from "../../../../hooks/useScreenwidth";
import { getData } from "../../../../services";
import { setLoading } from "../../../../store/slices/loaderSlice";
import ContentContainer from "../../../atoms/ContentContainer";
import Heading from "../../../atoms/Heading";
import Search from "../../../atoms/Search";
import TabStrip from "../../../molecules/TabStrip";
import FilterComponent from "../../../molecules/filters/FilterComponent";
import ProductRequest from "./ProductRequest";
import RequestedProduct from "./RequestedProduct";

const Requests = () => {
  const { query } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [cookies] = useCookies(["t", "cuid"]);
  const [requestData, setRequestData] = useState([]);
  const [requestedProduct, setRequestedProduct] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [searchedValue, setSearchedValue] = useState("");
  const [filters, setFilters] = useState({});
  const [activeTab, setActiveTab] = useState("Received");
  const [currentPageProduct, setCurrentPageProduct] = useState(1);
  const [itemsPerPageProduct, setItemsPerPageProduct] = useState(10);
  const screenwidth = useScreenWidth();

  useEffect(() => {
    if (query) {
      const updatedQuery = query.charAt(0).toUpperCase() + query.slice(1);
      setActiveTab(updatedQuery);
    }
  }, [query]);

  const getRequestData = useCallback(async () => {
    try {
      dispatch(setLoading(true));
      const res = await getData({
        endpoint: "RequestAccess/getMyProductRequest",
        params: {
          search_query: searchedValue,
          user_id: cookies.cuid,
          page: currentPage,
          page_limit: itemsPerPage,
          start_date: filters?.startDate,
          end_date: filters?.endDate,
          status:
            filters?.Status?.length > 0
              ? JSON.stringify(filters?.Status)
              : null,
        },
        token: cookies.t,
      });

      dispatch(setLoading(false));
      setRequestData(res);
    } catch (error) {
      console.log(error);
    }
  }, [cookies.t, searchedValue, filters, itemsPerPage, currentPage]);

  const getRequestedProduct = async () => {
    try {
      const res = await getData({
        endpoint: "RequestAccess/getMyRequestedProduct",
        params: {
          user_id: cookies.cuid,
          page: currentPageProduct,
          page_limit: itemsPerPageProduct,
          search_query: searchedValue,
          start_date: filters?.startDate,
          end_date: filters?.endDate,
          status:
            filters?.Status?.length > 0
              ? JSON.stringify(filters?.Status)
              : null,
        },
        token: cookies.t,
      });
      setRequestedProduct(res);
    } catch (exp) {
      console.log("Error", exp);
    }
  };

  useEffect(() => {
    getRequestedProduct();
  }, [
    activeTab,
    searchedValue,
    filters,
    currentPageProduct,
    itemsPerPageProduct,
  ]);

  useEffect(() => {
    getRequestData();
  }, [getRequestData]);

  // Handle page change
  const handlePageChange = useCallback((page) => {
    setCurrentPage(page);
  }, []);

  // Handle items per page change
  const handleItemsPerPageChange = useCallback((perPage) => {
    setCurrentPage(1); // Reset current page to 1
    setItemsPerPage(perPage);
  }, []);

  // Handle Product page change
  const handlePageChangeProduct = useCallback((page) => {
    setCurrentPageProduct(page);
  }, []);

  // Handle items Product per page change
  const handleItemsPerPageChangeProduct = useCallback((perPage) => {
    setCurrentPageProduct(1); // Reset current page to 1
    setItemsPerPageProduct(perPage);
  }, []);

  const dataArray = [
    {
      id: 0,
      label: "Received",
      view: (
        <ProductRequest
          requestData={requestData}
          getRequestData={getRequestData}
          itemsPerPage={itemsPerPage}
          currentPage={currentPage}
          handleItemsPerPageChange={handleItemsPerPageChange}
          handlePageChange={handlePageChange}
          refetchProductRequest={() => getRequestData()}
          searchedValue={searchedValue}
          filters={filters}
        />
      ),
    },
    {
      id: 1,
      label: "Sent",
      view: (
        <RequestedProduct
          requestedProduct={requestedProduct}
          currentPageProduct={currentPageProduct}
          itemsPerPageProduct={itemsPerPageProduct}
          handleItemsPerPageChangeProduct={handleItemsPerPageChangeProduct}
          handlePageChangeProduct={handlePageChangeProduct}
          searchedValue={searchedValue}
          filters={filters}
        />
      ),
    },
  ];
  const _getComponent = (tabs) => {
    return dataArray
      .filter((item) => item.label === tabs)
      .map((item) => item.view)[0];
  };

  const onTabChange = (event, value) => {
    setActiveTab(value);
    navigate(`/dashboard/requests/${value}`);
  };

  const handleSearchEmit = (searchValue) => {
    setSearchedValue(searchValue);
  };

  return (
    <ContentContainer type="dashboard" id="Requests-Container">
      <Heading
        type="main"
        title="Requests"
        classes="request-heading-container"
      />

      <div className="mt-large remove-top-margin-request">
        <div className="d-flex justify-content-between aligh-items-center request-padding-space">
          <Search
            placeholder="Search by name"
            onSearchEmit={handleSearchEmit}
            phonestyle={{ minWidth: "80vw", maxWidth: "80vw" }}
          />

          <FilterComponent
            headingMobile={screenwidth < 500 ? true : false}
            filterData={filterData}
            isDateRange={true}
            onFilterChange={(data) => {
              setFilters(data);
            }}
            type="right"
          />
        </div>

        <div className="requests-tab-div">
          <TabStrip
            activeTab={activeTab}
            onTabChange={onTabChange}
            data={dataArray}
          />
          <Box sx={{ width: "100%" }}>
            <Box>{_getComponent(activeTab)}</Box>
          </Box>
        </div>
      </div>
    </ContentContainer>
  );
};

const filterData = {
  Status: ["Pending", "Approved", "Denied"],
};

export default Requests;
