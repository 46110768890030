import * as signalR from "@microsoft/signalr";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import { timeAgo } from "../../../../helpers/timeAgo";
import { getData, postData } from "../../../../services";
import DocumentIcon from "../../../Icons/Document";
import NotificationIcon from "../../../Icons/notification";
import IconCircle from "../../../atoms/IconCircle";
import style from "./styles.module.scss";

const Notification = () => {
  const [cookies] = useCookies(["t", "cuid"]);
  const [notifications, setNotifications] = useState([]);
  const [showRed, setShowRed] = useState(false);
  const [notificationData, setNotificationData] = useState([]);
  const [unread, setUnread] = useState([]);
  const [unreadTypes, setUnreadTypes] = useState({});
  const [loading, setLoading] = useState();

  const baseURL = process.env.REACT_APP_NOTIFICATION_HUB_URL;

  const [notificationCount, setNotificationCount] = useState();
  const navigate = useNavigate();

  const [showDropdown, setShowDropdown] = useState(false);

  // Initially Creating a connection and fetching previous notifications
  useEffect(() => {
    setupSignalRConnection();
    getNotificationData();
  }, []);

  const handleUnreadNotifications = async () => {
    if (!!showDropdown && unread?.length > 0) {
      // console.log("show", showDropdown);
      // debugger;
      const res = await postData({
        endpoint: "RequestAccess/updateNotificationFlag",
        params: {
          request_ids:
            unreadTypes?.request_ids?.length > 0
              ? JSON.stringify(unreadTypes?.request_ids)
              : null,
          blog_ids:
            unreadTypes?.blog_ids?.length > 0
              ? JSON.stringify(unreadTypes?.blog_ids)
              : null,
        },
        token: cookies.t,
      });

      if (res) {
        getNotificationData();
        setShowRed(false);
      }
    }
  };

  const handleBellClick = () => {
    setShowDropdown((prev) => !prev);
    setShowRed(false);
    getNotificationData();
    if (notificationCount > 0) {
      handleUnreadNotifications();
    }
  };

  //
  const getNotificationData = async () => {
    setLoading(true);
    const res = await getData({
      endpoint: "RequestAccess/getProductRequestNotification",
      params: {
        user_id: cookies.cuid,
      },
      token: cookies.t,
    });

    if (res) {
      // console.log(res);
      setNotificationData(res);
      setLoading(false);
    }
  };

  const handleDropdownItemClick = (notification) => {
    if (notification?.type === "Blog") {
      navigate(`/dashboard/my-blogs`);
    } else if (notification?.type) {
      navigate(`/dashboard/requests/${notification?.type}`);
    } else {
      navigate(`/dashboard/requests`);
    }
    setShowDropdown(false);
    setShowRed(false);
  };

  // Creationg Connection
  const setupSignalRConnection = () => {
    // Create a SignalR connection to your hub
    const connection = new signalR.HubConnectionBuilder()
      .withUrl(baseURL, {
        skipNegotiation: true,
        transport: signalR.HttpTransportType.WebSockets,
      })
      .configureLogging(signalR.LogLevel.Information)
      .withAutomaticReconnect()
      .build();
    connection.start().catch((error) => {
      console.error(error);
    });

    // a client method to handle received notifications
    connection.on("SendNotification", (notification) => {
      if (notification[0]?.user_id || notification?.user_id) {
        if (notification[0]?.user_id === cookies.cuid) {
          setShowRed(true);
          setNotificationData((prevData) => [notification[0], ...prevData]);
        } else if (notification?.user_id === cookies.cuid) {
          setShowRed(true);
          setNotificationData((prevData) => [notification, ...prevData]);
        }
      } else {
        if (notification[0]?.main_contact_person === cookies.cuid) {
          setShowRed(true);
          setNotificationData((prevData) => [notification[0], ...prevData]);
        }
      }
    });
  };

  // useEffect(() => {
  //   console.log("notification-dta", notificationData);
  // }, [notificationData]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      const notificationIcon = document.getElementById("notificationIcon");
      const dropdown = document.getElementById("notificationDropdown");

      if (
        !notificationIcon.contains(event.target) &&
        dropdown &&
        !dropdown.contains(event.target)
      ) {
        setShowDropdown(false);
        handleUnreadNotifications();
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [showDropdown]);

  // finding unread notifications
  useEffect(() => {
    const unreadNotifications = notificationData.filter(
      (notification) => notification.notification_flag === true
    );
    const unreadNotId = unreadNotifications?.map(
      (notification) => notification.id
    );

    const separatedIds = unreadNotifications.reduce(
      (acc, notification) => {
        if (notification.type === "Blog") {
          acc.blog_ids.push(notification.id);
        } else {
          acc.request_ids.push(notification.id);
        }
        return acc;
      },
      { blog_ids: [], request_ids: [] }
    );

    setUnreadTypes(separatedIds);

    // const uniqueUnread = [...new Set(unreadNotId)];
    setUnread(unreadNotId);
    const count = unreadNotId.length;
    if (count > 0) {
      setShowRed(true);
    }
    setNotificationCount(count);
  }, [notificationData]);

  return (
    <div className={style.notification}>
      <i
        className="cursor-pointer grey-i"
        id="notificationIcon"
        onClick={handleBellClick}
      >
        <NotificationIcon />
      </i>

      {showRed && <div className={style.notificationCount}></div>}

      {showDropdown && (
        <div className={style.dropdown} id="notificationDropdown">
          {/* Add your amazing dropdown content here */}
          {loading ? (
            // Shimmer effect while loading
            <div className={style.shimmerContainer}>
              <div className={style.shimmer}></div>
              <div className={style.shimmer}></div>
              <div className={style.shimmer}></div>
            </div>
          ) : (
            <>
              <div className="d-flex justify-content-between p-3 align-items-center py-1">
                <div className="h3-i semi-light">
                  {notificationCount > 0 && (
                    <>
                      {notificationCount}{" "}
                      {notificationCount > 1 ? "Requests" : "Request"}
                    </>
                  )}
                </div>

                {notificationData.length > 0 && (
                  <div
                    className="view-more cursor-pointer"
                    onClick={() => {
                      navigate("/dashboard/requests");
                      setShowDropdown(false);
                      handleUnreadNotifications();
                    }}
                  >
                    View All
                  </div>
                )}
              </div>
              {notificationData.length > 0 ? (
                notificationData
                  .filter((notification, index, array) => {
                    // Use indexOf to check if the current notification's id is the first occurrence in the array
                    return (
                      array.indexOf(
                        array.find((item) => item.id === notification.id)
                      ) === index
                    );
                  })
                  ?.map((notification, index) => (
                    <>
                      <div
                        className={`${style.dropdownItem} ${
                          notification?.notification_flag
                            ? style.unreadNotification
                            : ""
                        }`}
                        onClick={() => handleDropdownItemClick(notification)}
                      >
                        <div className="d-flex gap-3">
                          <IconCircle
                            icon={
                              <i
                                className="d-flex"
                                style={{ color: "#1A1D1F" }}
                              >
                                <DocumentIcon />
                              </i>
                            }
                            size={50}
                          ></IconCircle>
                          <div className="d-flex flex-column">
                            {notification?.type === "Received" ? (
                              <>
                                <p className={style.notification_p}>
                                  <span className="semi-light">
                                    {notification?.user_name}
                                  </span>{" "}
                                  requested access for{" "}
                                  <span className="semi-light">
                                    {notification?.name}
                                  </span>
                                </p>
                              </>
                            ) : notification?.type === "Blog" ? (
                              <>
                                <p className={style.notification_p}>
                                  <span className="semi-light">PrimeAlpha</span>{" "}
                                  {notification?.status} request for{" "}
                                  <span className="semi-light">
                                    {notification?.name}
                                  </span>
                                </p>
                              </>
                            ) : (
                              <>
                                <p className={style.notification_p}>
                                  <span className="semi-light">
                                    {notification?.user_name}
                                  </span>{" "}
                                  {notification?.status} request for{" "}
                                  <span className="semi-light">
                                    {notification?.name}
                                  </span>
                                </p>
                              </>
                            )}

                            <p className="p mb-xs">
                              {timeAgo(notification?.date)}
                            </p>
                            {/* <p>{notification?.notification_flag}</p> */}
                          </div>
                        </div>
                      </div>
                    </>
                  ))
              ) : (
                <div className="d-flex align-items-center justify-content-center">
                  <div className="h3-i semi-light mb-1">
                    No New Notifications
                  </div>
                </div>
              )}
            </>
          )}
          {/* Add more items as needed */}
        </div>
      )}
      {/* )} */}
    </div>
  );
};

export default Notification;
