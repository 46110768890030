import { useEffect, useRef, useState } from "react";
import { useCookies } from "react-cookie";
import { getData } from "../../../../../../../services";
import {
  getGeography,
  getSector,
  getType,
} from "../../../../../../../services/editHelper";

const FundsFunction = ({ type }) => {
  const [cookies] = useCookies(["t"]);
  const [productType, setProductType] = useState([]);
  const [geography, setGeography] = useState([]);
  const [sector, setSector] = useState([]);
  const [categoryData, setCategoryData] = useState([]);

  const isMounted = useRef(true);

  const fetchCategoryData = async () => {
    const res = await getData({
      endpoint: "Taxonomy/getCategories",
      params: {},
      token: cookies.t,
    });
    setCategoryData(res);
  };

  const fetchType = async () => {
    const res = await getType();
    if (res) {
      const labelCount = res.reduce((acc, item) => {
        acc[item.label] = (acc[item.label] || 0) + 1;
        return acc;
      }, {});

      // Step 2: Modify label only if the count is greater than 1
      const modifiedRes = res.map((item) => ({
        ...item,
        label:
          labelCount[item.label] > 1
            ? `${item.label} (${item.structure_name})`
            : item.label,
      }));

      setProductType(modifiedRes);
    }
  };

  const fetchGeography = async () => {
    const res = await getGeography();
    if (res) {
      setGeography(res);
    }
  };

  const fetchSector = async () => {
    const res = await getSector();
    if (res) {
      setSector(res);
    }
  };
  useEffect(() => {
    if (isMounted.current) {
      if (type === "product") {
        fetchType();
        fetchGeography();
        fetchSector();
        fetchCategoryData();
      }
    }
    return () => {
      isMounted.current = false;
    };
  }, []);

  return {
    productType,
    geography,
    sector,
    categoryData,
  };
};
export default FundsFunction;
