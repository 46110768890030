import { Field, Formik } from "formik";
import { useEffect, useState } from "react";
import * as yup from "yup";
import "./styles.scss";

import Error from "../../../atoms/Error";
import InputLayout from "../../../atoms/InputLayout";
import ImageSelector from "../../../molecules/ImageSelector/ImageSelector";

import { countryCodeData } from "../../../../helpers/countryCodes";
import { replaceSpacesInImageUrl } from "../../../../helpers/replaceSpaceInImageUrl";
import SelectDropdown from "../../../atoms/Dropdown";
import AddPeopleModal from "./AddPeopleModal";
import { getComapnyData, getStateData, getcityDataById } from "./peopleService";

import { useNavigate, useParams } from "react-router-dom";
import { RoleType, TeamType } from "../../../../helpers/staticData";
import useScreenWidth from "../../../../hooks/useScreenwidth";
import QuillEditor from "../../../molecules/QuillEditor/QuillEditor";
import EditBlock from "../../../templates/BlockEdit";
import PeopleTab from "./PeopleTab/index";

const EditPeople = ({ type = "edit" }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [emailExists, setEmailExists] = useState(null);
  const [selectedCompanyType, setSelectedCompanyType] = useState();
  const [selectedValue, setSelectedValue] = useState(null);
  const [producteditActive, setProductEditActive] = useState(false);

  sessionStorage.setItem("peopleTab", "Investment Preference");

  const screenWidth = useScreenWidth();

  useEffect(() => {
    if (screenWidth <= 900) {
      setProductEditActive(false);
    } else {
      setProductEditActive(true);
    }
  }, [screenWidth]);

  const {
    statusData,
    companyData,
    stateData,
    contryData,
    RoleTypeData,
    cityDataList,
    handleSubmit,
    peopleDataById,
    setStateData,
    setCityData,
    handleAdd,
    setCompanyData,
    handleEditDraft,
    fetchedCompanyData,
  } = AddPeopleModal();

  const handleCompanyRoleType = (value) => {
    setSelectedCompanyType([
      { label: value[0], value: value[0] },
      { label: value[1], value: value[1] },
    ]);
  };

  const handleSelectChange = (value) => {
    setSelectedValue(value);
  };

  const handleClear = () => {
    // Handle the clear event, for example, set the selected value to null
    setSelectedValue(null);
  };
  const initialValues = {
    profile_photo: "",
    first_name: peopleDataById?.first_name || "",
    last_name: peopleDataById?.last_name || "",
    job_title: peopleDataById?.job_title || "",
    email: peopleDataById?.email || "",
    mobile: peopleDataById?.contact_number || "",
    company_id: peopleDataById?.company_id,
    team: peopleDataById?.team || "",
    team_type: "",
    role: peopleDataById?.role || "",
    status: peopleDataById?.status || "",
    country: peopleDataById?.country || "",
    country_code: peopleDataById?.country_code || "",
    ext_1: peopleDataById?.ext_1 || "",

    country_code_2: peopleDataById.country_code_2 || "",
    phone_2: peopleDataById?.phone_2 || "",
    ext_2: peopleDataById?.ext_2 || "",

    country_code_3: peopleDataById.country_code_3 || "",
    phone_3: peopleDataById?.phone_3 || "",
    company_type: peopleDataById?.company_type,
    state: peopleDataById?.state_region || "",
    city: peopleDataById?.city || "",
    about: peopleDataById?.about || "",
    is_admin: peopleDataById?.is_admin || false,
    profile_claimed: peopleDataById?.profile_claimed || false,
    show_on_company: peopleDataById?.show_on_company || false,
    investor_database: peopleDataById?.investor_database || false,
    resource: peopleDataById?.resource || false,
    content_sharing: peopleDataById?.content_sharing || false,
    manager_database: peopleDataById?.manager_database || false,
    investment_library_education:
      peopleDataById?.investment_library_education || false,
    is_private_profile: peopleDataById?.is_private_profile || false,
    primealpha_update_date: peopleDataById?.primealpha_update_date || null,
    tag: peopleDataById?.tag,
  };

  const validationSchema = yup.object().shape({
    first_name: yup.string().required("First name is required"),
    last_name: yup.string().required("last name is required"),
    job_title: yup.string().required("Job title is required filed"),
    email: yup.string().email("Invalid email").required("Email is required"),
    country_code: yup
      .string()
      .nullable()
      .test(
        "country-code-and-phone-number",
        "Either both Country Code and Phone should be provided, or neither",
        function (value) {
          const { country_code, mobile } = this.parent;
          if ((country_code && !mobile) || (!country_code && mobile)) {
            return false;
          }
          return true;
        }
      ),
    mobile: yup
      .string()
      .nullable()
      .test(
        "country-code-and-phone-number",
        "Either both Country Code and Contact Number should be provided, or neither",
        function (value) {
          const { country_code, mobile } = this.parent;
          if ((country_code && !mobile) || (!country_code && mobile)) {
            return false;
          }
          return true;
        }
      ),
    ext_1: yup
      .string()
      .nullable()
      .test(
        "country-code-and-phone-number-ext1",
        "Ext. 1 can only be added when Phone is provided.",
        function (value) {
          const { country_code, mobile } = this.parent;
          if (value && (!country_code || !mobile)) {
            return false;
          }
          return true;
        }
      ),
    country_code_2: yup
      .string()
      .nullable()
      .test(
        "country-code-and-phone-number",
        "Either both Country Code and Contact Number should be provided, or neither",
        function (value) {
          const { country_code_2, phone_2 } = this.parent;
          if ((country_code_2 && !phone_2) || (!country_code_2 && phone_2)) {
            return false;
          }
          return true;
        }
      ),
    phone_2: yup
      .string()
      .nullable()
      .test(
        "country-code-and-phone-number",
        "Either both Country Code and Contact Number should be provided, or neither",
        function (value) {
          const { country_code_2, phone_2 } = this.parent;
          if ((country_code_2 && !phone_2) || (!country_code_2 && phone_2)) {
            return false;
          }
          return true;
        }
      ),
    ext_2: yup
      .string()
      .nullable()
      .test(
        "country-code-and-phone-number-ext1",
        "Ext. 2 can only be added when Secondary Phone is provided",
        function (value) {
          const { country_code_2, phone_2 } = this.parent;
          if (value && (!country_code_2 || !phone_2)) {
            return false;
          }
          return true;
        }
      ),
    country_code_3: yup
      .string()
      .nullable()
      .test(
        "country-code-and-phone-number",
        "Either both Country Code and Contact Number should be provided, or neither",
        function (value) {
          const { country_code_3, phone_3 } = this.parent;
          if ((country_code_3 && !phone_3) || (!country_code_3 && phone_3)) {
            return false;
          }
          return true;
        }
      ),
    phone_3: yup
      .string()
      .nullable()
      .test(
        "country-code-and-phone-number",
        "Either both Country Code and Contact Number should be provided, or neither",
        function (value) {
          const { country_code_3, phone_3 } = this.parent;
          if ((country_code_3 && !phone_3) || (!country_code_3 && phone_3)) {
            return false;
          }
          return true;
        }
      ),
    company_id: yup.string().required("Company Name is reuired"),
    status: yup.string().required("Status is required"),
    role: yup.string().required("Role is required"),
    state: yup.string().required("State is required"),
    city: yup.string().required("City iss required"),
    country: yup.string().required("Country is required"),
    is_admin: yup.boolean().required("This field must be checked"),
    show_on_company: yup.boolean().required("this field is required"),
    profile_claimed: yup.boolean().required("This field must be checked"),
  });

  const setPermissions = (value, setFieldValue) => {
    if (value === "Investor") {
      setFieldValue("investor_database", false);
      setFieldValue("resource", false);
    }
    if (value === "Manager/Sponsor") {
      setFieldValue("manager_database", false);
      setFieldValue("investment_library_education", false);
    }
  };

  return (
    <div className="edit-container">
      {producteditActive && (
        <>
          <div className="h-j">Edit Person</div>

          <Formik
            key={Math.random}
            initialValues={initialValues}
            validationSchema={validationSchema}
            enableReinitialize
          >
            {({
              isValid,
              dirty,
              isSubmitting,
              touched,
              errors,
              values,
              handleChange,
              handleBlur,
              setFieldValue,
            }) => (
              <>
                <div className="edit-page-display mb-4">
                  <div className="left-side">
                    <div className="form-card">
                      <div className="p-0 pt-4">
                        <h4 className="h3-i semi">Person Details</h4>
                        <hr className="mt-0" />
                        <label>Photo</label>
                        <ImageSelector
                          handleFile={(file) => {
                            setFieldValue("profile_photo", file);
                            setFieldValue("remove_profile_photo", false);
                          }}
                          selectedImage={replaceSpacesInImageUrl(
                            peopleDataById?.profile_photo
                          )}
                          description="Image size should be 400X400px and size is 1MB"
                          onRemove={() =>
                            setFieldValue("remove_profile_photo", true)
                          }
                        />

                        <div className="row g-3 mt-3">
                          <InputLayout>
                            <label htmlFor="" className="required-field">
                              First Name
                            </label>
                            <Field
                              type="text"
                              name="first_name"
                              placeholder="Enter First name"
                              className={`${
                                touched.first_name && errors.first_name
                                  ? "error-input"
                                  : ""
                              }`}
                              autoComplete="add-people-data"
                              onBlur={(e) => {
                                handleBlur(e);
                              }}
                            />
                            {touched.first_name && errors.first_name && (
                              <Error error={errors.first_name} />
                            )}
                          </InputLayout>

                          <InputLayout>
                            <label htmlFor="" className="required-field">
                              Last Name
                            </label>
                            <Field
                              type="text"
                              name="last_name"
                              placeholder="Enter Last name"
                              className={`${
                                touched.last_name && errors.last_name
                                  ? "error-input"
                                  : ""
                              }`}
                              onBlur={(e) => {
                                handleBlur(e);
                              }}
                              autoComplete="add-people-data"
                            />
                            {touched.last_name && errors.last_name && (
                              <Error error={errors.last_name} />
                            )}
                          </InputLayout>
                        </div>

                        <div className="row g-3 mt-3">
                          <InputLayout>
                            <label htmlFor="" className="required-field">
                              Job Title
                            </label>
                            <Field
                              type="text"
                              name="job_title"
                              placeholder="Enter Job Title"
                              className={`${
                                touched.job_title && errors.job_title
                                  ? "error-input"
                                  : ""
                              }`}
                              onBlur={(e) => {
                                handleBlur(e);
                              }}
                              autoComplete="add-people-data"
                            />
                            {touched.job_title && errors.job_title && (
                              <Error error={errors.job_title} />
                            )}
                          </InputLayout>

                          <InputLayout>
                            <label htmlFor="" className="required-field">
                              Email
                            </label>
                            <Field
                              type="text"
                              name="email"
                              disabled={type === "edit"}
                              placeholder="Enter Email ID"
                              className={`${
                                touched.email && (errors.email || emailExists)
                                  ? "error-input"
                                  : ""
                              }`}
                              onBlur={(e) => {
                                // checkEmailValidity(e.target.value);
                                handleBlur(e);
                              }}
                              autoComplete="add-people-data"
                            />

                            {touched.email && (errors.email || emailExists) && (
                              <Error error={errors.email || emailExists} />
                            )}
                          </InputLayout>
                        </div>

                        <div className="row g-3 mt-3">
                          <InputLayout>
                            <label htmlFor="">Phone Number 1</label>
                            <div className="row g-3 w-100">
                              <div className="col-4">
                                <SelectDropdown
                                  data={countryCodeData}
                                  placeholder="Code"
                                  className={`${
                                    // eslint-disable-next-line react/prop-types
                                    touched.country_code && errors.country_code
                                      ? "error-input"
                                      : ""
                                  }`}
                                  onChange={(option) =>
                                    setFieldValue("country_code", option)
                                  }
                                  isClearable={true}
                                  onClear={() =>
                                    setFieldValue("country_code", "")
                                  }
                                  type="phoneNumber"
                                  selectedValue={peopleDataById.country_code}
                                />
                              </div>
                              <div className="col-8 pe-0">
                                <Field
                                  type="text"
                                  name="mobile"
                                  placeholder="Enter Phone Number 1"
                                  className={`w-100 h-100 ${
                                    touched.mobile && errors.mobile
                                      ? "error-input"
                                      : ""
                                  }`}
                                  onBlur={(e) => {
                                    handleBlur(e);
                                  }}
                                  onChange={(e) => {
                                    const numericInput = e.target.value.replace(
                                      /[^0-9]/g,
                                      ""
                                    );
                                    setFieldValue("mobile", numericInput);
                                  }}
                                  autoComplete="add-people-data"
                                />
                              </div>
                              {(errors.country_code || errors.mobile) && (
                                <Error
                                  error={errors.country_code || errors.mobile}
                                />
                              )}
                            </div>
                          </InputLayout>

                          <InputLayout>
                            <label htmlFor="">Ext. 1</label>
                            <Field
                              type="text"
                              className={`w-100 h-100 ${
                                touched.ext_1 && errors.ext_1
                                  ? "error-input"
                                  : ""
                              }`}
                              name="ext_1"
                              placeholder="Enter Ext. 1"
                              autoComplete="add-people-data"
                            />
                            {(errors.ext_1 || errors.ext_1) && (
                              <Error error={errors.ext_1} />
                            )}
                          </InputLayout>
                        </div>

                        <div className="row g-3 mt-3">
                          <InputLayout>
                            <label htmlFor="">Phone Number 2</label>
                            <div className="row g-3 w-100">
                              <div className="col-4">
                                <SelectDropdown
                                  data={countryCodeData}
                                  placeholder="Code"
                                  className={`${
                                    touched.country_code_2 &&
                                    errors.country_code_2
                                      ? "error-input"
                                      : ""
                                  }`}
                                  onChange={(option) =>
                                    setFieldValue("country_code_2", option)
                                  }
                                  isClearable={true}
                                  type="phoneNumber"
                                  onClear={() =>
                                    setFieldValue("country_code_2", "")
                                  }
                                  selectedValue={peopleDataById.country_code_2}
                                />
                              </div>
                              <div className="col-8 pe-0">
                                <Field
                                  type="text"
                                  name="phone_2"
                                  placeholder="Enter Phone Number 2"
                                  className={`w-100 h-100 ${
                                    touched.phone_2 && errors.phone_2
                                      ? "error-input"
                                      : ""
                                  }`}
                                  onBlur={(e) => {
                                    handleBlur(e);
                                  }}
                                  onChange={(e) => {
                                    const numericInput = e.target.value.replace(
                                      /[^0-9]/g,
                                      ""
                                    );
                                    setFieldValue("phone_2", numericInput);
                                  }}
                                  autoComplete="add-people-data"
                                />
                              </div>
                              {(errors.country_code_2 || errors.phone_2) && (
                                <Error
                                  error={
                                    errors.country_code_2 || errors.phone_2
                                  }
                                />
                              )}
                            </div>
                          </InputLayout>

                          <InputLayout>
                            <label htmlFor="">Ext. 2</label>
                            <Field
                              type="text"
                              className={`w-100 h-100 ${
                                touched.ext_2 && errors.ext_2
                                  ? "error-input"
                                  : ""
                              }`}
                              name="ext_2"
                              placeholder="Enter Ext. 2"
                              autoComplete="add-people-data"
                            />
                            {(errors.ext_2 || errors.ext_2) && (
                              <Error error={errors.ext_2} />
                            )}
                          </InputLayout>
                        </div>

                        <div className="row g-3 mt-3">
                          <InputLayout>
                            <label htmlFor="">Phone Number 3</label>
                            <div className="row g-3 w-100">
                              <div className="col-4">
                                <SelectDropdown
                                  data={countryCodeData}
                                  placeholder="Code"
                                  className={`${
                                    touched.country_code_3 &&
                                    errors.country_code_3
                                      ? "error-input"
                                      : ""
                                  }`}
                                  onChange={(option) =>
                                    setFieldValue("country_code_3", option)
                                  }
                                  isClearable={true}
                                  type="phoneNumber"
                                  onClear={() =>
                                    setFieldValue("country_code_3", "")
                                  }
                                  selectedValue={peopleDataById.country_code_3}
                                />
                              </div>
                              <div className="col-8 pe-0">
                                <Field
                                  type="text"
                                  name="phone_3"
                                  placeholder="Enter Phone Number 3"
                                  className={`w-100 h-100 ${
                                    touched.phone_3 && errors.phone_3
                                      ? "error-input"
                                      : ""
                                  }`}
                                  onBlur={(e) => {
                                    handleBlur(e);
                                  }}
                                  onChange={(e) => {
                                    const numericInput = e.target.value.replace(
                                      /[^0-9]/g,
                                      ""
                                    );
                                    setFieldValue("phone_3", numericInput);
                                  }}
                                  autoComplete="add-people-data"
                                />
                              </div>
                              {(errors.country_code_3 || errors.phone_3) && (
                                <Error
                                  error={
                                    errors.country_code_3 || errors.phone_3
                                  }
                                />
                              )}
                            </div>
                          </InputLayout>

                          <InputLayout>
                            <label htmlFor="" className="required-field">
                              Company Type
                            </label>
                            {fetchedCompanyData?.company_type ? (
                              <input
                                disabled
                                value={fetchedCompanyData?.company_type}
                              />
                            ) : (
                              <SelectDropdown
                                data={RoleType}
                                autoComplete="add-people-data"
                                onChange={async (option) => {
                                  const data = await getComapnyData(option);
                                  setCompanyData(data);
                                  setFieldValue(
                                    "company_type",
                                    option.length > 1
                                      ? "Investor/Manager/Sponsor"
                                      : option[0]
                                  );
                                  handleCompanyRoleType(option);
                                }}
                                format="all"
                                multiSelect={true}
                                onClear={(e) => {
                                  handleBlur(e);
                                }}
                                isDisabled={true}
                                placeholder="Select Type"
                                selectedValue={
                                  values?.company_type === "Investor"
                                    ? ["Investor"]
                                    : values?.company_type === "Manager/Sponsor"
                                    ? ["Manager/Sponsor"]
                                    : values?.company_type ===
                                      "Investor/Manager/Sponsor"
                                    ? ["Investor", "Manager/Sponsor"]
                                    : null
                                }
                              />
                            )}
                          </InputLayout>
                        </div>

                        <div className="row g-3 mt-3">
                          <InputLayout>
                            <label htmlFor="">Company Name</label>
                            {fetchedCompanyData?.name ||
                            peopleDataById?.company_name ? (
                              <input
                                disabled
                                value={
                                  fetchedCompanyData?.name ||
                                  peopleDataById?.company_name
                                }
                              />
                            ) : (
                              <SelectDropdown
                                data={companyData}
                                autoComplete="add-people-data"
                                onChange={(option) => {
                                  handleSelectChange();
                                  setFieldValue("company_id", option);
                                }}
                                onClear={handleClear}
                                placeholder="Select Company Name"
                                selectedValue={peopleDataById?.company_id}
                                isDisabled
                              />
                            )}
                          </InputLayout>
                          <InputLayout>
                            <label htmlFor="" className="required-field">
                              Team Type
                            </label>
                            <SelectDropdown
                              data={TeamType}
                              autoComplete="add-people-data"
                              format="all"
                              multiSelect={true}
                              onChange={(option) => {
                                handleSelectChange();
                                setFieldValue("team", option);
                              }}
                              onClear={handleClear}
                              placeholder="Team Type"
                              selectedValue={peopleDataById?.team}
                            />
                          </InputLayout>
                        </div>

                        <div className="row g-3 mt-3">
                          <InputLayout>
                            <label htmlFor="" className="required-field">
                              Status
                            </label>
                            <SelectDropdown
                              data={statusData}
                              autoComplete="add-people-data"
                              onChange={(option) => {
                                handleSelectChange();
                                setFieldValue("status", option);
                              }}
                              onClear={handleClear}
                              placeholder="Select Status"
                              selectedValue={peopleDataById?.status}
                            />
                          </InputLayout>
                          <InputLayout>
                            <label htmlFor="" className="required-field">
                              Role
                            </label>
                            {/* {fetchedCompanyData ? (
                          <input disabled value={fetchedCompanyData?.name} />
                        ) : ( */}
                            <SelectDropdown
                              data={selectedCompanyType || RoleTypeData}
                              autoComplete="add-people-data"
                              onChange={(option) => {
                                if (option.length > 1) {
                                  setFieldValue(
                                    "role",
                                    "Investor/Manager/Sponsor"
                                  );
                                  setPermissions(values.role, setFieldValue);
                                } else {
                                  setFieldValue("role", option[0]);
                                  setPermissions(values.role, setFieldValue);
                                }
                              }}
                              onClear={handleClear}
                              placeholder="Select Role"
                              selectedValue={peopleDataById?.role}
                              multiSelect={true}
                            />
                            {/* )} */}
                          </InputLayout>
                        </div>

                        <div className="row g-3 mt-3">
                          <InputLayout>
                            <label htmlFor="" className="required-field">
                              Country
                            </label>
                            <SelectDropdown
                              data={contryData}
                              autoComplete="add-people-data"
                              onChange={async (option) => {
                                handleSelectChange();
                                setFieldValue("country", option);
                                const data = await getStateData(option);
                                setStateData(data);
                              }}
                              onClear={handleClear}
                              placeholder="Select Country"
                              selectedValue={peopleDataById?.country}
                            />
                          </InputLayout>
                          <div className="col-md">
                            <div className="row g-3">
                              <div className="col-md">
                                <div className="form-group">
                                  <label htmlFor="" className="required-field">
                                    State
                                  </label>
                                  <SelectDropdown
                                    data={stateData}
                                    autoComplete="add-people-data"
                                    onChange={async (option) => {
                                      handleSelectChange();
                                      setFieldValue("state", option);
                                      const data = await getcityDataById(
                                        option
                                      );
                                      setCityData(data);
                                    }}
                                    onClear={handleClear}
                                    placeholder="Select State"
                                    selectedValue={peopleDataById?.state_region}
                                  />
                                </div>
                              </div>

                              <div className="col-md">
                                <div className="form-group">
                                  <label htmlFor="" className="required-field">
                                    City
                                  </label>
                                  <SelectDropdown
                                    data={cityDataList}
                                    autoComplete="add-people-data"
                                    onChange={(option) => {
                                      handleSelectChange();
                                      setFieldValue("city", option);
                                    }}
                                    onClear={handleClear}
                                    placeholder="Select City"
                                    selectedValue={peopleDataById?.city}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row mt-3">
                          <div className="form-group">
                            {/* <Field
                              type="textarea"
                              component="textarea"
                              name="about"
                              placeholder="Enter About"
                              cols="30"
                              rows="4"
                            /> */}
                            <QuillEditor
                              label="About"
                              placeholderText="Enter About"
                              // onChange={async (content) => {
                              //   setFieldValue("about", content);
                              // }}
                              onChange={async (content) => {
                                await setFieldValue("about", content);
                              }}
                              hp="80%"
                              height="300px"
                              type=""
                              value={values.about}
                            />
                          </div>
                        </div>

                        <div className="row mt-3">
                          <div className="col-md">
                            <div className="add-page-switch">
                              <label htmlFor="" className="mb-0">
                                Is Admin
                              </label>
                              <div className="form-switch">
                                {values.is_admin ? "Yes" : "No"}
                                <input
                                  className="form-check-input ms-3"
                                  type="checkbox"
                                  role="switch"
                                  id="flexSwitchCheckDefault"
                                  name="isAdmin"
                                  checked={values.is_admin}
                                  value={values.is_admin}
                                  onChange={(e) => {
                                    values.is_admin = !values.is_admin;
                                    handleChange(e);
                                  }}
                                  onBlur={handleBlur}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="col-md">
                            <div className="add-page-switch">
                              <label htmlFor="" className="mb-0">
                                Show on Company
                              </label>
                              <div className="form-switch">
                                {values.show_on_company ? "Yes" : "No"}
                                <input
                                  className="form-check-input ms-3"
                                  type="checkbox"
                                  role="switch"
                                  id="flexSwitchCheckDefault"
                                  name="showOnCompany"
                                  checked={values.show_on_company}
                                  value={values.show_on_company}
                                  onChange={(e) => {
                                    values.show_on_company =
                                      !values.show_on_company;
                                    handleChange(e);
                                  }}
                                  onBlur={handleBlur}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* 
                        <div className="row mt-3">
                          <div className="col-md">
                            <div className="add-page-switch">
                              <label htmlFor="" className="mb-0">
                                Profile Claimed
                              </label>
                              <div className="form-switch">
                                {values.profile_claimed ? "Yes" : "No"}
                                <input
                                  className="form-check-input ms-3"
                                  type="checkbox"
                                  role="switch"
                                  id="flexSwitchCheckDefault"
                                  name="contentSharing"
                                  checked={values.profile_claimed}
                                  value={values.profile_claimed}
                                  onChange={(e) => {
                                    values.profile_claimed =
                                      !values.profile_claimed;
                                    handleChange(e);
                                  }}
                                  onBlur={handleBlur}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md">
                             <div className="add-page-switch">
                              <label htmlFor="" className="mb-0">
                                Private Profile
                              </label>
                              <div className="form-switch">
                                {values.is_private_profile ? "Yes" : "No"}
                                <input
                                  className="form-check-input ms-3"
                                  type="checkbox"
                                  role="switch"
                                  id="flexSwitchCheckDefault"
                                  name="contentSharing"
                                  checked={values.is_private_profile}
                                  value={values.is_private_profile}
                                  onChange={(e) => {
                                    values.is_private_profile =
                                      !values.is_private_profile;
                                    handleChange(e);
                                  }}
                                  onBlur={handleBlur}
                                />
                              </div>
                            </div> 
                          </div>
                        </div> */}
                      </div>
                    </div>
                    {id && <PeopleTab role={values?.role} />}
                  </div>

                  <div className="right-side pt-4"></div>
                </div>

                <footer className="footer ">
                  <div className="d-flex gap-3">
                    <button
                      type="submit"
                      className="btn btn-outline-dark px-4 mt-0 "
                      onClick={() => navigate(-1)}
                    >
                      Back
                    </button>
                    <button
                      type="submit"
                      className="btn btn-primary px-4 mt-0 "
                      disabled={!isValid}
                      onClick={() => handleSubmit(values)}
                    >
                      Update Details
                    </button>
                  </div>
                </footer>
              </>
            )}
          </Formik>
        </>
      )}

      {screenWidth <= 900 && <EditBlock />}
    </div>
  );
};

export default EditPeople;
