import { Box } from "@mui/material";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import Default from "../../../../../../../assets/images/default.png";
import Default1 from "../../../../../../../assets/images/default2.png";
import { areObjectsEqual } from "../../../../../../../helpers/areObjectsEqual";
import { getInvestorData } from "../../../../../../../services/investorService";
import CloseButton from "../../../../../../atoms/CloseButton";
import GoogleMapCustom from "../../../../../../atoms/GoogleMap";
import Search from "../../../../../../atoms/Search";
import TypeDiv from "../../../../../../atoms/TypeDiv";
import NoContentCard from "../../../../../../molecules/NoContentCard";
import Pagination from "../../../../../../molecules/Pagination";
import TabStrip from "../../../../../../molecules/TabStrip/index";
import styles from "./styles.module.scss";

import useScreenWidth from "../../../../../../../hooks/useScreenwidth";
import { getData } from "../../../../../../../services";
import ResetBtn from "../../../../../../atoms/ResetBtn/ResetBtn";
import ArrowDown from "../../../../../../Icons/ArrowDown";
import MultiselectFilter from "../../../../../../organisms/MultiselectFilter";
import EditBlock from "../../../../../../templates/BlockEdit";
import CreatedInvestorFilter from "../../../Filter/CreatedInvestorFilter";
import UpdateInvestorFilter from "../../../Filter/UpdatedInvestorFilter";
import InvestorPreferance from "../../../RoadShow/InvestorPreferance";
import OverView from "../../../RoadShow/OverView";
import AumFilter from "../../Filters/AumFilter";
import Location from "../../NewFilter/Location";
import Preferances from "../../NewFilter/Preferances";

const Index = () => {
  const [cookies] = useCookies(["t"]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [searchvalue, setSearchvalue] = useState();
  const [investorCompanyId, setInvestoreCompanyId] = useState();

  const [resetAll, setResetAll] = useState(false);
  const [locationData, setLocationData] = useState();
  const [markerlatlan, setmarkerlatlan] = useState();
  const [InvestorList, setInvestoreList] = useState([]);
  const [investorAddressData, setInvestoreAddressData] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDataThere, setIsDataThere] = useState(true);
  const [filters, setFilters] = useState({});

  const [activeTab, setActiveTab] = useState("overview");
  const screenWidth = useScreenWidth();
  const [investorType, setInvestorType] = useState();
  const [filterOpenInvestorType, setFilterOpenInvestorType] = useState(false);
  const [investorTypeFilter, setInvestorTypeFilter] = useState([]);
  const [aum, setAum] = useState({ min: null, max: null });
  const [locationFilter, setLocationFilter] = useState({});
  const [filterOpenCreated, setFilterOpenCreated] = useState(false);
  const [filterOpenUpdated, setFilterOpenUpdated] = useState(false);

  const [createdFilter, setCreatedFilter] = useState();
  const [updatedFilter, setUpdatedFilter] = useState();
  const [filterOpenTags, setFilterOpenTags] = useState(false);
  const [tagFilter, setTagFilter] = useState();
  const [tagData, setTagData] = useState([]);

  const onTabChange = (event, value) => {
    setActiveTab(value);
  };

  const _getComponent = (tabs) => {
    return dataArray
      .filter((item) => item.label === tabs)
      .map((item) => item.view)[0];
  };

  let dataArray = [
    {
      id: 0,
      label: "overview",
      view: (
        <OverView
          details={investorAddressData?.[0]?.overview}
          company_id={investorAddressData?.[0]?.company_id}
        />
      ),
    },
    {
      id: 1,
      label: "Investment Preference",
      view: (
        <InvestorPreferance company_id={investorAddressData?.[0]?.company_id} />
      ),
    },
  ];

  const handlePageChange = useCallback((page) => {
    setCurrentPage(page);
  }, []);

  const handleItemsPerPageChange = useCallback((perPage) => {
    setCurrentPage(1);
    setItemsPerPage(perPage);
  }, []);

  const fetchInvestorData = async () => {
    // dispatch(setLoading(true));
    const res = await getData({
      endpoint: "Taxonomy/getInvestorTypeList",
      params: {
        status: true,
      },
      token: cookies.t,
    });
    // dispatch(setLoading(false));
    setInvestorType(res);
  };
  const fetchTags = async () => {
    const res = await getData({
      endpoint: "Search/getTags",
      params: {
        type: "Investor",
      },
      token: cookies.t,
    });
    let result = [];
    res.forEach((item) => {
      if (item !== null) {
        result.push({ value: item, label: item });
      }
    });
    setTagData(result);
  };
  useEffect(() => {
    fetchInvestorData();
    fetchTags();
  }, []);

  const handleResetAll = () => {
    setResetAll(true);
  };

  useEffect(() => {
    const getInvestorList = async () => {
      try {
        const res = await getInvestorData({
          type: "roadshow",
          token: cookies.t,
          search: searchvalue,
          currentPage,
          itemsPerPage,
          investorType: investorTypeFilter,
          aum: aum,
          country:
            locationFilter?.Country &&
            locationFilter?.["Country"]?.map((country) => country.label),
          state:
            locationFilter?.State &&
            locationFilter?.["State"]?.map((state) => state.label),
          city:
            locationFilter?.City &&
            locationFilter?.["City"]?.map((city) => city.label),
          sector:
            filters?.Sector &&
            filters?.["Sector"]?.map((sector) => sector.value),
          geography:
            filters?.Geography &&
            filters?.["Geography"]?.map((geography) => geography.value),
          product_type:
            filters?.["Product Type"] &&
            filters?.["Product Type"]?.map((type) => type.value),
          tags: tagFilter,
          // updatedTag?.Tags && updatedTag?.["Tags"]?.map((tag) => tag.label),
          created_start_date: createdFilter?.created_start_date,
          created_end_date: createdFilter?.created_end_date,
          updated_start_date: updatedFilter?.updated_start_date,
          updated_end_date: updatedFilter?.updated_end_date,
          // country:
          //   filters?.Country &&
          //   filters?.["Country"]?.map((country) => country.label),
          // state:
          //   filters?.State && filters?.["State"]?.map((state) => state.label),
          // city: filters?.City && filters?.["City"]?.map((city) => city.label),
          // investorType:
          //   filters?.["Investor Type"] &&
          //   filters?.["Investor Type"]?.map((type) => type.value),
        });
        setInvestoreList(res);
        setInvestoreAddressData(res?.data);
        if (res?.data?.length === 0) {
          setIsModalOpen(false);
          setIsDataThere(false);
        }
      } catch (exp) {
        console.log("InvestorList", exp);
      }
    };
    getInvestorList();
  }, [
    searchvalue,
    itemsPerPage,
    currentPage,
    investorTypeFilter,
    aum,
    filters,
    locationFilter,
    tagFilter,
    updatedFilter,
    createdFilter,
    cookies.t,
  ]);

  const handleClickAdress = (clickAddress) => {
    if (locationData?.country !== (null || undefined)) {
      clickAddress.address = clickAddress?.address?.filter(
        (item) => item?.country === locationData?.country
      );
      setInvestoreAddressData([clickAddress]);
    } else {
      setInvestoreAddressData([clickAddress]);
    }
    setIsModalOpen(true);
  };

  // filtered location data --------------
  const filteredLocationData = InvestorList?.data?.map((item) => {
    if (filters?.["Country"]?.length > 0) {
      const address = item?.address?.filter(
        (data) =>
          filters?.["Country"].includes(data?.country) &&
          filters?.["State"].includes(data?.state_region) &&
          filters?.["City"].includes(data?.city)
      );
      return {
        ...item,
        address: address,
      };
    } else {
      return {
        ...item,
      };
    }
  });

  const onCloseButton = () => {
    setIsModalOpen(false);
  };

  const handleSearch = (value) => {
    setSearchvalue(value);
    if (currentPage !== 1) {
      setCurrentPage(1);
    }
  };

  const handleFilterChange = (values) => {
    if (Object.keys(values)?.length > 0) {
      const modifiedData = Object.keys(values).reduce((acc, key) => {
        const newKey = key;
        acc[newKey] = values[key];
        return acc;
      }, {});

      // Check if the new modifiedData is different from the existing filters
      if (!areObjectsEqual(modifiedData, filters)) {
        if (currentPage !== 1) setCurrentPage(1);
        setFilters(modifiedData);
      }
    } else {
      setFilters({});
    }
  };

  return (
    <>
      {screenWidth > 900 ? (
        <div className={styles.roadshowContainer}>
          <div className="ms-3 d-flex justify-content-between me-3">
            <div className="h1-i">Roadshow</div>
            <Search variant="search_md" onSearchEmit={handleSearch} />
          </div>
          <div className={`${styles.roadshowHeader} mt-3`}>
            {/* 
            <div className="d-flex gap-2">
              <FilterComponent
                onFilterChange={handleFilterChange}
                type="investorRoadshow"
                headingMobile={false} // Set to true if you want to hide the "Filters" button text on mobile
                reset={resetAll}
              />
            </div> */}
            <div className={`d-flex gap-2`}>
              <MultiselectFilter
                title="Investor Type"
                icon={<ArrowDown />}
                data={investorType}
                handleFilter={(filter) => {
                  setInvestorTypeFilter(filter);
                  setResetAll(false);
                }}
                open={filterOpenInvestorType}
                setOpen={setFilterOpenInvestorType}
                id="InvestorTypeId"
                // dropdownPosition={dropdownPositions.investor}
                sizeType="small"
                reset={resetAll}
              />
              <AumFilter
                handleAum={(data) => {
                  setAum(data);
                }}
                reset={resetAll}
                id="aumDropdown"
                // dropdownPosition={dropdownPositions.aum}
              />
              <Location
                onFilterChange={(values) => {
                  setLocationFilter(values);
                }}
                type="product"
                headingtext="Location"
                headingMobile={false}
                disableKeysUntilCondition={true}
                reset={resetAll}
                id={"location_filter"}
              />

              <Preferances
                onFilterChange={(values) => {
                  setFilters(values);
                }}
                type="product"
                headingtext="Preference"
                headingMobile={false} // Set to true if you want to hide the "Filters" button text on mobile
                disableKeysUntilCondition={true} // Pass the disableKeysUntilCondition object
                reset={resetAll}
                id="more_Filters"
                // className={style.reset_responsive_Mobile_btn}
              />

              <CreatedInvestorFilter
                handleFilter={(data) => setCreatedFilter(data)}
                filterOpenCreated={filterOpenCreated}
                setFilterOpenCreated={setFilterOpenCreated}
                id="createdDropdown"
                // dropdownPosition={dropdownPositions.created}
                type="small"
                reset={resetAll}
              />
              <UpdateInvestorFilter
                handleFilter={(data) => setUpdatedFilter(data)}
                filterOpenUpdated={filterOpenUpdated}
                setFilterOpenUpdated={setFilterOpenUpdated}
                id="updatedDropdown"
                // dropdownPosition={dropdownPositions.updated}
                type="small"
                reset={resetAll}
              />
              <MultiselectFilter
                title="Tags"
                icon={<ArrowDown />}
                data={tagData}
                handleFilter={(filter) => {
                  setTagFilter(filter);
                  setResetAll(false);
                }}
                open={filterOpenTags}
                setOpen={setFilterOpenTags}
                // setQuickSearchData={setQuickSearchData}
                id="tagsDropdown"
                // dropdownPosition={dropdownPositions.tags}
                sizeType="small"
                reset={resetAll}
              />

              <ResetBtn onReset={() => handleResetAll()} type="small" />
            </div>
          </div>

          <div className={styles.roadshowMap}>
            <GoogleMapCustom
              InvestorList={investorAddressData}
              markerlatlan={markerlatlan}
              setmarkerlatlan={setmarkerlatlan}
            />

            <div className={styles.dataDiv}>
              <>
                {InvestorList?.data?.length > 0 ? (
                  <>
                    {filteredLocationData?.map((item) => {
                      return (
                        <div
                          className={
                            item?.company_id === investorCompanyId
                              ? styles.InvestorListWrapClicked
                              : styles.investorListWrap
                          }
                          onClick={() => {
                            handleClickAdress(item);
                            setInvestoreCompanyId(item?.company_id);
                          }}
                        >
                          <h6 className={styles.companyName}>{item?.name}</h6>
                          <div className={styles.companyType}>{item?.type}</div>
                          {item?.address?.length > 0 && (
                            <div className={styles.companyType}>
                              {locationData?.country !== (null || undefined) ? (
                                <>
                                  {item?.address?.[0]?.city +
                                    ", " +
                                    item?.address?.[0]?.state_region +
                                    ", " +
                                    item?.address?.[0]?.country}
                                </>
                              ) : (
                                <>
                                  {item?.address?.[0]?.city +
                                    ", " +
                                    item?.address?.[0]?.state_region +
                                    ", " +
                                    item?.address?.[0]?.country}
                                </>
                              )}
                            </div>
                          )}
                          <div className={styles.ItemDatesInfo}>
                            {item?.created_date && (
                              <div className={styles.formattedDate}>
                                Added on{" "}
                                {moment
                                  .utc(item?.created_date)
                                  .format("MMM D, YYYY")}
                              </div>
                            )}
                            {item?.updated_date && (
                              <li className={styles.formattedDate}>
                                Last updated on{" "}
                                {moment
                                  .utc(item?.updated_date)
                                  .format("MMM D, YYYY")}
                              </li>
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </>
                ) : isDataThere ? (
                  //

                  <></>
                ) : (
                  <div style={{ scale: "0.7" }} className="mt-4">
                    <NoContentCard title="No Data Found" />
                  </div>
                )}
              </>
              {InvestorList?.total_record > 5 && (
                <div className="mt-2">
                  <Pagination
                    itemsPerPage={itemsPerPage}
                    totalItems={InvestorList?.total_record}
                    // totalCountData={InvestorList?.total_record}
                    currentPage={currentPage}
                    isShowResult={false}
                    onPageChange={handlePageChange}
                    onItemsPerPageChange={handleItemsPerPageChange}
                  />
                </div>
              )}
            </div>
            {isModalOpen && (
              <>
                <div className={styles.googleGlobalWrap}>
                  <div className={styles.googleGlobalInnerWrap}>
                    <div className={styles.imgcloseWrap}>
                      <img
                        src={investorAddressData?.[0]?.cover_photo || Default}
                        alt="company_image"
                      />

                      <CloseButton
                        className="close-icon-pop-up"
                        onClose={onCloseButton}
                        styles={{ position: "absolute" }}
                      />

                      <img
                        className={styles.LogoRoadMap}
                        src={
                          investorAddressData?.[0]?.profile_photo || Default1
                        }
                        alt="roadshow"
                      ></img>

                      <div
                        className={styles.viewCta}
                        onClick={() => {
                          window.open(
                            `/dashboard/company/${investorAddressData?.[0]?.company_id}`,
                            "_blank"
                          );
                        }}
                      >
                        View
                      </div>
                    </div>
                    <div style={{ paddingLeft: "10px", marginTop: "40px" }}>
                      <div className={styles.companyHeaderRoad}>
                        <div className={styles.companyNamepopup}>
                          {investorAddressData?.[0]?.name}
                        </div>
                      </div>
                      <div className={styles.companyHeaderRoad}>
                        {investorAddressData?.[0]?.address?.length > 0 && (
                          <div
                            className={styles.companyType}
                            style={{ paddingBottom: "0" }}
                          >
                            {investorAddressData?.[0]?.address?.[0]
                              ?.state_region +
                              ", " +
                              investorAddressData?.[0]?.address?.[0]?.country}
                          </div>
                        )}
                        <TypeDiv data="Company" color="#CABDFF" />
                      </div>
                    </div>
                    <div style={{ paddingLeft: "20px", paddingRight: "20px" }}>
                      <TabStrip
                        activeTab={activeTab}
                        onTabChange={onTabChange}
                        data={dataArray}
                        allowedTabId="0"
                        margin="1rem"
                        type="small"
                      />
                    </div>

                    <Box sx={{ width: "100%" }}>
                      <Box sx={{ padding: "0 20px", pt: 2 }}>
                        {_getComponent(activeTab)}
                      </Box>
                    </Box>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      ) : (
        <EditBlock name="RoadShow" />
      )}
    </>
  );
};

export default Index;
