import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { confimationStyles } from "../../../../../../assets/styles/toast";
import { dataRoomFilterData } from "../../../../../../helpers/staticData";
import { getData } from "../../../../../../services";
import Search from "../../../../../atoms/Search";
import BlogFilters from "../../../../../organisms/blogFilters";
import DataRoomImage from "./DataRoomImage";
import "./dataroom.scss";

import ShareIcon from "../../../../../Icons/ShareIcon";
import View from "../../../../../Icons/View";
import ShareLink from "../../Edit/DataRoom/shareLink";
import ShareLinkEmail from "../../Edit/DataRoom/shareLinkEmail";
import ViewData from "./View";

import { setLoading } from "../../../../../../store/slices/loaderSlice";
import Modal from "../../../../../molecules/Modal/Normal";
import NoContentCard from "../../../../../molecules/NoContentCard";

import { useSelector } from "react-redux";
import { htmlToTextWithSpaces } from "../../../../../../helpers/htmlToText";
import Download from "../../../../../Icons/Download";
import CustomButton from "../../../../../atoms/CustomeButton";
import DateRangeFilter from "./DateRangeFilter";

const DataRoom = ({ company_id, product_id }) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [cookies] = useCookies(["cid"]);
  const [generateURL, setGenerateURL] = useState();
  const [isCopy, setIsCopy] = useState(false);
  const [expiry, setExpiry] = useState(false);

  const userInfo = useSelector((state) => state.peopleData?.peopleData);

  const sharedBase = process.env.USER_BASE_URL;

  const [searchedValue, setSearchedValue] = useState("");
  const [categories, setCategories] = useState(); // Use string[] for categories
  const [data, setData] = useState([]);
  const [dateFilter, setDateFilter] = useState({
    start_date: null,
    end_date: null,
  });

  const [modalState, setModalState] = useState({
    isModalOpen: false,
    modalId: "",
    title: "",
    FormData: "",
    isViewModalOpen: false,
    isShareLinkModalOpen: false,
    isShareLink: false,
  });

  const getDataRoomData = useCallback(async () => {
    dispatch(setLoading(true));
    try {
      const res = await getData({
        endpoint: "Product/getAllDataRoomData",
        params: {
          search_query: searchedValue,
          product_id: product_id || id,
          page: 1,
          page_limit: 100,
          type: JSON.stringify(categories),
          is_featured: true,
          start_date: dateFilter.start_date,
          end_date: dateFilter.end_date,
        },
        token: cookies.t,
      });
      dispatch(setLoading(false));

      setData(res?.data);
    } catch (error) {
      console.log(error);
    }
  }, [
    searchedValue,
    categories,
    cookies.t,
    id,
    dateFilter?.start_date,
    dateFilter?.end_date,
  ]);

  useEffect(() => {
    getDataRoomData();
  }, [
    searchedValue,
    categories,
    cookies.t,
    id,
    dateFilter?.start_date,
    dateFilter?.end_date,
  ]);

  const handleSearchEmit = (searchValue) => {
    setSearchedValue(searchValue);
  };

  const handleFilterSelect = (option) => {
    if (option === "All") {
      setCategories(null);
    } else {
      setCategories([option]); // Use spread operator to correctly update state
    }
  };

  const handleLink = (data) => {
    if ((data?.type === "Podcast" && data?.document) || data.link) {
      const modalId = uuidv4();
      setModalState((prev) => ({
        ...prev,
        title: "View Data",
        modalId: modalId,
        FormData: data,
        isViewModalOpen: true,
      }));
    } else if (data?.document) {
      // Open the document in a new tab
      const documentUrl = data.document; // Assuming data.document is the URL of the document
      window.open(documentUrl, "_blank");
    }
  };

  const handleShare = (data) => {
    const modalId = uuidv4();
    setModalState((prev) => ({
      ...prev,
      title: "Share Content",
      modalId: modalId,
      FormData: data,
      isShareLink: true,
    }));
  };

  const handleShareLinkEmail = () => {
    const modalId = uuidv4();
    setModalState((prev) => ({
      ...prev,
      title: "Share Content",
      modalId: modalId,
      isShareLinkModalOpen: true,
    }));
    // setModalState((prev) => ({
    //   ...prev,
    //   isViewModalOpen: false,
    // }));
  };

  const handleCopyUrl = () => {
    var text = document?.getElementById("primealphaurl")?.innerHTML;

    // Decode HTML entities
    const tempElement = document.createElement("div");
    tempElement.innerHTML = text;
    text = tempElement.textContent;

    navigator?.clipboard?.writeText(text);
    setIsCopy(true);
    toast.success("Link Copied to clipboard.", {
      style: confimationStyles,
      duration: 1000,
    });
  };

  //This is using beacause it is becoming problem in mobile its opening case written in normal modal and here we are enabiling scrolling
  if (!modalState.isViewModalOpen) {
    document.body.style.position = "";
    document.body.style.top = "";
  }

  const handleDateFilter = (data) => {
    setDateFilter(data);
  };

  return (
    <>
      <div className="dataroom-container">
        <div className="h3-j semi pro-info-heading mb-3">Data Room</div>

        <div className="dataroom-header mb-4">
          <BlogFilters
            data={dataRoomFilterData}
            onFilterSelect={handleFilterSelect}
          />

          <div className="d-flex gap-3">
            <Search
              onSearchEmit={handleSearchEmit}
              variant="search_lg"
              placeholder="Search by Name, Tag"
            />
            <DateRangeFilter handleFilter={handleDateFilter} />
          </div>
        </div>

        <div className="dataroom-container">
          <>
            {data.length > 0 ? (
              <>
                {data?.map((item) => (
                  <div className="dataroom-item" key={item?.data_id}>
                    <div className="dataroom-left-side">
                      <DataRoomImage
                        thumbnail={item?.thumbnail}
                        type={item?.type}
                        sub_type={item.sub_type}
                      />
                      <div className="item-detail">
                        <p className="mb-2 p">{item?.tag?.join(", ")}</p>
                        <div className="h4-j mb-2">{item?.name}</div>
                        {item.description && (
                          <p className="p-dark-light mb-2">
                            {htmlToTextWithSpaces(item?.description)}
                          </p>
                        )}
                        <p className="p">
                          {moment
                            .utc(item?.upload_date || item?.modified_date)
                            .format("MMM D, YYYY")}
                        </p>
                      </div>
                    </div>
                    <div className="dataroom-right-side">
                      {item?.type === "Video" ||
                      item?.type === "Podcast" ||
                      item?.sub_type === "Pdf" ? (
                        <CustomButton
                          icon={
                            <View
                              className="Lock-white-container"
                              width="17"
                              height="17"
                            />
                          }
                          classes="btn-xs"
                          type="btn-primary"
                          text="View"
                          handleClick={() => handleLink(item)}
                          style={{ minWidth: "120px" }}

                          //  forMobile={screenWidth < 480 ? true : false}
                        />
                      ) : (
                        <CustomButton
                          icon={<Download className="Lock-white-container" />}
                          classes="btn-xs"
                          type="btn-primary"
                          text="Download"
                          handleClick={() => handleLink(item)}
                          style={{ minWidth: "120px" }}
                          //  forMobile={screenWidth < 480 ? true : false}
                        />
                      )}

                      {(userInfo?.has_master_access ||
                        (company_id === cookies.cid && userInfo.is_admin)) && (
                        // <IconCircle
                        //   isClickable
                        //   border="1px solid #efefef"
                        //   icon={
                        //     <i className="d-flex">
                        //       <ShareIcon />
                        //     </i>
                        //   }
                        //   color="#ffffff"
                        //   size={40}
                        //   handleClick={() => handleShare(item)}
                        // />
                        <CustomButton
                          icon={
                            // <EditBlue
                            //   className="black-fill"
                            //   width="16"
                            //   height="15"
                            // />
                            <ShareIcon />
                          }
                          classes="btn-xs"
                          text="Share"
                          handleClick={() => handleShare(item)}
                        />
                      )}
                    </div>
                  </div>
                ))}
              </>
            ) : (
              <>
                <NoContentCard title="Sorry! No Result Found" type="Search" />
              </>
            )}
          </>
        </div>
      </div>

      {modalState.isViewModalOpen && (
        <Modal
          id={modalState.modalId}
          isActive={modalState.isViewModalOpen}
          onClose={() =>
            setModalState((prev) => ({
              ...prev,
              isViewModalOpen: false,
            }))
          }
          title="View Data"
          width="642px"
        >
          <ViewData data={modalState.FormData} id={modalState.modalId} />
        </Modal>
      )}

      {modalState.isShareLink && (
        <Modal
          id={modalState.modalId}
          isActive={modalState.isShareLink}
          title={modalState.title}
          width="642px"
          onClose={() => {
            setModalState((prev) => ({
              ...prev,
              isShareLink: false,
            }));
            setGenerateURL(null);
          }}
          bodyStyle={{ padding: "24px" }}
        >
          <ShareLink
            name={modalState.FormData?.name}
            id={modalState.modalId}
            roomData={modalState.FormData}
            handleShareLinkEmail={handleShareLinkEmail}
            generateURL={generateURL}
            setGenerateURL={setGenerateURL}
            setExpiry={setExpiry}
            isCopy={isCopy}
            setIsCopy={setIsCopy}
            handleCopyUrl={handleCopyUrl}
            company_id={company_id}
          />
        </Modal>
      )}

      {modalState.isShareLinkModalOpen && (
        <Modal
          id={modalState.modalId}
          isActive={modalState.isShareLinkModalOpen}
          title={modalState.title}
          width="642px"
          onClose={() =>
            setModalState((prev) => ({
              ...prev,
              isShareLinkModalOpen: false,
            }))
          }
        >
          <ShareLinkEmail
            generateURL={generateURL}
            isCopy={isCopy}
            handleCopyUrl={handleCopyUrl}
            setModalStateEmail={setModalState}
            setGenerateURL={setGenerateURL}
            onClose={() =>
              setModalState((prev) => ({
                ...prev,
                isShareLinkModalOpen: false,
                isShareLink: false,
              }))
            }
            expiry={expiry}
          />
        </Modal>
      )}
    </>
  );
};

export default DataRoom;
