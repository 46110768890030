import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Default from "../../../../assets/images/default.png";
import { formatNumber } from "../../../../helpers/numberFormatter";
import { getData } from "../../../../services";
import { loaderData, setLoading } from "../../../../store/slices/loaderSlice";
import Heading from "../../../atoms/Heading";
import ComapanyStats, {
  StatsConatiner,
} from "../../../templates/InfoPage/CompanyStats/ComapanyStats";
import HeroSection from "../../../templates/InfoPage/HeroSection/HeroSection";
import ImageContent, {
  ImageContentContainer,
  ImageContentHeading,
} from "../../../templates/InfoPage/ImageContent/ImageContent";
import LatestAdded, {
  AddLatest,
} from "../../../templates/InfoPage/LastAdded/LatestAdded";
import LibraryCatalog from "../../../templates/InfoPage/LibaryCatalog/Index";
import PortfolioCard, {
  PortCards,
} from "../../../templates/InfoPage/PortfolioCards/portfolioCard";
import QuickSearch from "../../../templates/InfoPage/QuickSearch/QuickSearch";
import "../Investors/investor.scss";
import ShimmerProduct from "./Shimmer/shimmer";
import ContentMarketing from "./requirnment/ContentMarketing.png";
import Educational from "./requirnment/Educational.png";

const Products = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loadingDataValue = useSelector(loaderData);
  const userInfo = useSelector((state) => state.peopleData?.peopleData);

  const [subscribed, setSubscribed] = useState(true);
  const [redirect, setRedirect] = useState(false);
  const [ProductsPageData, setProductsPageData] = useState([]);
  const [productTypeData, setProductTypeData] = useState([]);

  const [cookies] = useCookies(["t", "product_access", "library_access"]);
  const getResourceData = useCallback(async () => {
    try {
      dispatch(setLoading(true));
      const res = await getData({
        endpoint: "Dashboard/getProductDashboardData",
        token: cookies.t,
      });
      dispatch(setLoading(false));
      setProductsPageData(res);
    } catch (error) {
      console.error(error);
    }
  }, []);

  const getInvestorTypeData = useCallback(async () => {
    const payload = {
      status: true,
    };
    try {
      const investorTypeList = await getData({
        params: payload,
        endpoint: "Taxonomy/getAllTypeList",
        token: cookies.t,
      });
      setProductTypeData(investorTypeList);
    } catch (exp) {
      console.log("Error", exp);
    }
  }, [cookies.t]);

  useEffect(() => {
    getResourceData();
    getInvestorTypeData();
  }, [getResourceData, getInvestorTypeData]);

  const handleButtonClick = () => {
    setRedirect(true);
  };

  const handleManagerDb = () => {
    navigate("/dashboard/products/productdatabase");
  };

  const handleLibrary = () => {
    navigate("/dashboard/library");
  };

  if (redirect) {
    window.location.href = userInfo?.product_learn_more_link;
  }
  const handleLock = () => {
    window.location.href = userInfo?.product_learn_more_link;
  };
  function capitalizeFirstLetterEachWord(str) {
    return str.replace(/\b\w/g, (match) => match.toUpperCase());
  }

  const handleQuickSearch = (data) => {
    if (userInfo?.has_master_access || userInfo?.manager_database) {
      navigate("/dashboard/products/productdatabase", {
        state: data,
      });
    } else {
      window.location.assign(userInfo?.product_learn_more_link);
    }
  };

  const handleLibraryClick = () => {
    if (userInfo?.has_master_access || userInfo?.investment_library_education) {
      return;
    } else {
      window.location.assign(userInfo?.product_learn_more_link);
    }
  };

  return (
    <div id="Fund-Directory-Container">
      {loadingDataValue ? (
        <ShimmerProduct />
      ) : (
        <div className="Investor-DataBase-Container">
          <Heading
            type="main"
            title="Fund Directory"
            classes="investor-heading-conatiner"
          />
          <HeroSection
            ImagePic={ProductsPageData?.cover_photo || Default}
            Heading="View Alternatives Directory"
            Button={
              userInfo?.has_master_access || userInfo?.manager_database
                ? "Go To Directory"
                : "Learn More"
            }
            onClick={
              userInfo?.has_master_access || userInfo?.manager_database
                ? handleManagerDb
                : handleButtonClick
            }
            HeadingStyle={true}
            // isButton2Visible={userInfo?.manager_database}
            // Button2={
            //   userInfo?.investment_library_education
            //     ? "Search Library"
            //     : "Search Library"
            // }
            // onClick2={
            //   userInfo?.investment_library_education
            //     ? handleLibrary
            //     : handleButtonClick
            // }
          />

          <ComapanyStats>
            <StatsConatiner
              key={ProductsPageData?.counts?.product_opportunities}
              count={formatNumber(
                ProductsPageData?.counts?.product_opportunities
              )}
              category="Funds & Opportunities"
            />

            <StatsConatiner
              key={ProductsPageData?.counts?.product_companies}
              count={formatNumber(ProductsPageData?.counts?.product_companies)}
              category="Companies"
            />

            <StatsConatiner
              key={ProductsPageData?.counts?.added_last_month}
              count={formatNumber(ProductsPageData?.counts?.added_last_month)}
              category="Companies Added Last 3 Months"
            />
          </ComapanyStats>

          <LatestAdded
            Heading="Latest Opportunities Added"
            dataLength={
              ProductsPageData?.company ? ProductsPageData?.company.length : 0
            }
          >
            {ProductsPageData?.company &&
              ProductsPageData?.company?.map((item) => (
                <AddLatest
                  ImageSrc={item?.logo}
                  alt="cananon-logo"
                  Heading={item?.name}
                  Content={item?.company_type}
                  link={item?.link}
                  userInfo={userInfo}
                  type="product"
                />
              ))}
          </LatestAdded>

          <QuickSearch
            quickSearchData={productTypeData}
            type="productType"
            handleQuickSearch={(data) => {
              handleQuickSearch(data);
            }}
          />

          <ImageContent>
            <ImageContentHeading
              Heading="Alternative Investments Hub"
              Information="Insights and Education on Alternative Investments. Leveraging the knowledge and expertise of over 1000 professionals in the alternatives industry."
              Content="Search Library"
              onClick1={
                userInfo?.has_master_access ||
                userInfo?.investment_library_education
                  ? handleLibrary
                  : handleButtonClick
              }
            ></ImageContentHeading>

            <div>
              <ImageContentContainer
                ImagePath={ContentMarketing}
                Heading="Educational Resources and Investment Insights"
                Content1="Discover the potential benefits of alternative investments and how they can complement traditional investment portfolios. Whether you're looking to diversify your holdings, enhance returns, or mitigate risk, alternative investments offer unique opportunities that may align with your investment objectives."
                Content2="Explore our educational resources to learn more about the advantages of incorporating alternative investments into your portfolio."
              ></ImageContentContainer>
            </div>

            <div className="mt-xxl">
              <ImageContentContainer
                style={{ flexDirection: "row-reverse" }}
                ImagePath={Educational}
                Heading="Portfolio Diversification and Differentiated Opportunities"
                Content1="Discover a world brimming with investment possibilities. PrimeAlpha offers you a comprehensive platform for initial fund research, linking you to innovative investment opportunities designed to provide attractive returns and diversify your portfolio."
                Content2="As your trusted partner, we help you navigate the diverse landscape of alternative investments with an unbiased approach. Begin your exploration with PrimeAlpha."
              ></ImageContentContainer>
            </div>
          </ImageContent>

          <div className="mt-xl">
            <PortfolioCard
              Heading="Recent Library Additions"
              subscribed={
                userInfo?.has_master_access ||
                userInfo?.investment_library_education
              }
              onClick={
                !userInfo?.has_master_access &&
                !userInfo.investment_library_education &&
                handleLock
              }
            >
              {ProductsPageData?.library?.data &&
                ProductsPageData?.library?.data?.slice(0, 4).map((item) => (
                  <PortCards
                    CardPic={item?.thumbnail || item?.cover_photo}
                    CardHeading={item?.header}
                    CardDate={moment.utc(item?.date).format("MMM D, YYYY")}
                    PicContent={item.category}
                    subscribed={subscribed}
                    id={item.id}
                    // onClick={handleLock}
                    onCardClick={() => {
                      (userInfo?.has_master_access ||
                        userInfo?.investment_library_education) &&
                        navigate(
                          `/dashboard/library/view/${item.id}/${btoa(
                            item.category
                          )}`
                        );
                    }}
                  ></PortCards>
                ))}
            </PortfolioCard>
          </div>

          <LibraryCatalog
            onClick={() => handleLibraryClick()}
            accessAllow={
              userInfo?.has_master_access ||
              userInfo?.investment_library_education
            }
          />
        </div>
      )}
    </div>
  );
};

export default Products;
