import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useDispatch } from "react-redux";
import { getData } from "../../../../../../services";
import { setLoading } from "../../../../../../store/slices/loaderSlice";
import InvestorPeople from "../InvestorPeople";

const OverView = ({ details, company_id }) => {
  const [cookies] = useCookies(["t"]);
  const dispatch = useDispatch();
  const [investmentData, setInvestmentData] = useState();
  const [expanded, setExpanded] = useState(false);
  const maxDisplayLength = 400;

  const fetchInvestmentData = async () => {
    dispatch(setLoading(true));
    const payload = {
      company_id: company_id,
      page: 1,
      page_limit: 1000,
    };
    const res = await getData({
      endpoint: "People/getCompanyPeopleDetails",
      params: payload,
      token: cookies.t,
    });
    if (res) {
      dispatch(setLoading(true));
      setInvestmentData(res);
    }
  };

  useEffect(() => {
    fetchInvestmentData();
  }, [company_id]);

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  const shouldDisplayViewMore = details.length > maxDisplayLength;

  const normalData = details === "<p><br></p>" ? "__" : details;

  const displayText = expanded
    ? normalData
    : normalData.substring(0, maxDisplayLength);

  return (
    <div>
      <h4 className="h4-j">About Company</h4>

      <div className="quill quill-change">
        <div
          className="ql-container ql-snow ql-no-border"
          style={{ background: "white" }}
        >
          <div
            className="ql-editor blog-content"
            style={{ padding: "0 !important" }}
            dangerouslySetInnerHTML={{
              __html: displayText,
            }}
          ></div>
        </div>
      </div>
      {shouldDisplayViewMore && (
        <span onClick={toggleExpand} className="view-more mt-1">
          {expanded ? "View less" : "View more"}
        </span>
      )}

      {investmentData?.data?.length > 0 && (
        <>
          <h4 className="h4-j mt-2">Team</h4>
          <InvestorPeople company_id={company_id} data={investmentData.data} />
        </>
      )}
    </div>
  );
};

export default OverView;
