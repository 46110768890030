import { getData } from ".";

export const getStatesById = (id, token) => {
  const params = {
    country_id: id,
  };

  const response = getData({
    endpoint: "Settings/getStateRegionByCountryId",
    params: params,
    token: token,
  });
  return response;
};

export const getCityById = (id, token) => {
  const params = {
    state_region_id: id,
  };
  const response = getData({
    endpoint: "Settings/getCityByStateRegionId",
    params: params,
    token: token,
  });
  return response;
};

export const getMultipleCityById = (ids, token) => {
  const params = {
    states: JSON.stringify(ids),
  };
  const response = getData({
    endpoint: "Settings/getCitiesByMultiState",
    params: params,
    token: token,
  });
  return response;
};

export const getmultipleStatesById = (ids, token) => {
  const params = {
    countries: JSON.stringify(ids),
  };
  const response = getData({
    endpoint: "Settings/getStatesByMultiCountry",
    params: params,
    token: token,
  });
  return response;
};

export const getStructureById = (id, token) => {
  const response = getData({
    endpoint: "Taxonomy/getStructureByCategory",
    params: { category_id: id },
    token: token,
  });
  return response;
};

export const getTypeById = (id, token) => {
  const response = getData({
    endpoint: "Taxonomy/getTypeByStructure",
    params: { structure_id: id },
    token: token,
  });
  return response;
};

export const getStrategyById = (id, token) => {
  const response = getData({
    endpoint: "Taxonomy/getStrategyByTypeId",
    params: { type_id: id },
    token: token,
  });

  return response;
};

export const getStrategyByMultipleTypeId = (id, token) => {
  const response = getData({
    endpoint: "Taxonomy/getStrategyByMultipleType",
    params: { type_ids: JSON.stringify(id) },
    token: token,
  });
  return response;
};

export const getSubStrategyById = (id, token) => {
  const response = getData({
    endpoint: "Taxonomy/getSubStrategyByStrategyId",
    params: { strategy_id: id },
    token: token,
  });
  return response;
};

export const getGeography = (token) => {
  const response = getData({
    endpoint: "Taxonomy/getGeography",
    params: {},
    token: token,
  });
  return response;
};

export const getSector = (token) => {
  const response = getData({
    endpoint: "Taxonomy/getSectors",
    params: {},
    token: token,
  });
  return response;
};

export const getCompanies = (type, token) => {
  const response = getData({
    endpoint: "Company/getCompanyList",
    params: { types: JSON.stringify(type) },
    token: token,
  });
  return response;
};

export const getPeopleByCompany = (id, token) => {
  const params = {
    company_id: id,
  };

  const response = getData({
    endpoint: "People/getCompanyPeople",
    params: params,
    token: token,
  });
  return response;
};

export const getStructure = async (id, token) => {
  const response = await getData({
    endpoint: "Taxonomy/getallProductStructure",
    params: { page_limit: 1000 },
    token: token,
  });

  const formattedStructureData = response?.data.map((structure) => {
    return {
      value: structure.structure_id,
      label: structure.structure,
    };
  });

  return formattedStructureData;
};

export const getCountries = async (token) => {
  const res = await getData({
    endpoint: "Settings/getCountries",
    params: {},
    token: token,
  });

  return res;
};

export const getType = async (token) => {
  const res = await getData({
    endpoint: "Taxonomy/getAllTypeList",
    params: {
      status: true,
    },
    token: token,
  });

  return res;
};
