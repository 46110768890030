// components/VimeoUpload.js
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { LoadingContext } from "../../../contexts/LoadingContext";

const VimeoUpload = () => {
  const navigate = useNavigate();
  const { startLoading, stopLoading } = useContext(LoadingContext);
  const [videoFile, setVideoFile] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [error, setError] = useState(null);
  const VIMEO_ACCESS_TOKEN = "8e8ce4b143a4eaee07a4d5c1b13115d8"; // Replace with your Vimeo token

  const handleFileChange = (e) => {
    setVideoFile(e.target.files[0]);
  };

  const handleUpload = async () => {
    if (!videoFile) {
      alert("Please select a video to upload");
      return;
    }

    startLoading(); // Start showing the loader
    setError(null); // Reset error state
    setUploadProgress(0); // Reset progress

    // Create a new worker for handling video upload
    const worker = new Worker(
      new URL("../../../contexts/LoadingContext/worker.js", import.meta.url)
    ); // Adjust the path as necessary

    worker.onmessage = (e) => {
      const { progress, completed, error } = e.data;

      if (progress !== undefined) {
        setUploadProgress(progress);
      }

      if (completed) {
        stopLoading(); // Hide the loader
        alert("Upload successful!"); // Handle successful upload
        worker.terminate(); // Terminate the worker when done
      }

      if (error) {
        setError(error); // Handle error
        stopLoading(); // Hide the loader
        worker.terminate(); // Terminate the worker on error
      }
    };

    // Step 1: Create an upload ticket
    try {
      const ticketResponse = await fetch("https://api.vimeo.com/me/videos", {
        method: "POST",
        headers: {
          Authorization: `bearer ${VIMEO_ACCESS_TOKEN}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          upload: {
            approach: "tus",
            size: videoFile.size,
          },
        }),
      });

      if (!ticketResponse.ok) {
        throw new Error("Failed to create upload ticket");
      }

      const {
        upload: { upload_link },
      } = await ticketResponse.json();

      // Step 2: Post data to the worker for uploading
      worker.postMessage({
        uploadLink: upload_link,
        videoFile,
        chunkSize: 1024 * 1024, // 1MB chunks
      });
    } catch (error) {
      setError(error.message); // Handle error
      stopLoading(); // Hide the loader
    }
  };

  return (
    <div>
      <h2>Vimeo Video Upload</h2>
      <input type="file" accept="video/*" onChange={handleFileChange} />
      <button onClick={handleUpload}>Upload Video</button>

      <button onClick={() => navigate("/dashboard")}> Dashboard</button>

      {uploadProgress > 0 && (
        <div>
          <p>Upload Progress: {uploadProgress}%</p>
        </div>
      )}

      {error && (
        <div>
          <p style={{ color: "red" }}>Error: {error}</p>
        </div>
      )}
    </div>
  );
};

export default VimeoUpload;
