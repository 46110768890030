import { IconButton } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useDelete } from "../../../../../../hooks/useDelete";
import { getData } from "../../../../../../services";
import { setLoading } from "../../../../../../store/slices/loaderSlice";
import Add from "../../../../../Icons/Add";
import DeleteRed from "../../../../../Icons/DeleteRed";
import EditBlue from "../../../../../Icons/EditBlue";
import Investement from "../../../../../Icons/Investment";
import View from "../../../../../Icons/View";
import CustomButton from "../../../../../atoms/CustomeButton";
import CustomDatagrid from "../../../../../molecules/CustomDatagrid";
import DeleteModal from "../../../../../molecules/Modal/DeleteModal";
import NoContentCard from "../../../../../molecules/NoContentCard";
import Pagination from "../../../../../molecules/Pagination";

const InvestmentPreference = ({ type }) => {
  const [cookies] = useCookies(["t", "cid"]);
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10); // Initial items per page
  const navigate = useNavigate();
  const [investmentData, setInvestmentData] = useState();

  const [modalState, setModalState] = useState({
    isDeleteModalOpen: false,
    modalId: "",
    name: "",
  });

  const fetchInvestmentData = async () => {
    dispatch(setLoading(true));
    const res = await getData({
      endpoint: "Company/getAllCompanyInvestmentPrefrence",
      params: { company_id: cookies.cid },
      token: cookies.t,
    });
    dispatch(setLoading(false));
    if (res) {
      setInvestmentData(res);
    }
  };

  useEffect(() => {
    fetchInvestmentData();
  }, []);

  const deletePreference = useDelete();

  const handleView = (data) => {
    navigate(`/dashboard/company/investment-preference/${data.id}`);
  };

  const handleDelete = (data) => {
    setModalState((prevState) => ({
      ...prevState,
      isDeleteModalOpen: true,
      modalId: data.id,
      name: data?.product_structure?.name || "",
    }));
  };

  const handleDeletion = async () => {
    try {
      await deletePreference(
        "Company/deleteCompanyInvestmentPrefrence",
        "Investment Preference",
        {
          prefrence_id: modalState.modalId,
          user_role: "Customer",
        }
      );
      fetchInvestmentData();
      setModalState((prevState) => ({
        ...prevState,
        isDeleteModalOpen: false,
      }));
    } catch (error) {
      console.log(error);
    }
  };

  const handleEdit = (data) => {
    navigate(`/dashboard/edit-company/edit-investment-preference/${data.id}`);
  };

  const preferenceColumns = [
    {
      field: "productDetails",
      headerName: "Product Type",
      flex: 1.3,
      renderCell: (params) => {
        return (
          <div className="d-flex flex-column">
            <span
              style={{ color: "#1A1D1F", fontWeight: 800, fontSize: "14px" }}
            >
              {params.row.product_type?.name}
            </span>
            {/* <span style={{ color: "#6F767E", fontSize: "14px" }}>
              {params.row.product_type?.name}
            </span> */}
          </div>
        );
      },
    },
    {
      field: "productStructure",
      headerName: "Product Structure",
      flex: 1.3,
      renderCell: (params) => {
        return (
          <div className="d-flex flex-column">
            {/* <span
              style={{ color: "#1A1D1F", fontWeight: 800, fontSize: "14px" }}
            >
              {params.row.product_structure?.name}
            </span> */}
            <span style={{ color: "#6F767E", fontSize: "14px" }}>
              {params.row.product_structure?.name}
            </span>
          </div>
        );
      },
    },
    {
      field: "aumThreshhold",
      headerName: "AUM Threshold",
      flex: 1,
      renderCell: (params) => {
        return (
          <span>
            {params.row?.min_aum && `$${params.row?.min_aum}`}
            {params.row?.min_aum && params.row?.max_aum && (
              <>&nbsp;-&nbsp;</>
            )}{" "}
            {params.row?.max_aum && ` $${params.row?.max_aum} `}
          </span>
        );
      },
    },
    {
      field: "lengthTrackRecord",
      headerName: "Track Length Record",
      flex: 1,
      renderCell: (params) => {
        return (
          <span>
            {params.row?.min_track_record}{" "}
            {params.row?.min_track_record && params.row?.max_track_record && (
              <>&nbsp;-&nbsp;</>
            )}
            {params.row?.max_track_record}&nbsp;
            {(params.row?.max_track_record || params.row?.min_track_record) &&
              "years"}
          </span>
        );
      },
    },
    {
      field: "preLaunch",
      headerName: "Pre-Launch",
      flex: 1,
      renderCell: (params) => {
        return <span>{params.row.prelaunch ? "Yes" : "No"}</span>;
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <IconButton type="button" onClick={() => handleView(params.row)}>
              <i className="actions-icon-custom">
                <View />
              </i>
            </IconButton>

            <IconButton type="button" onClick={() => handleEdit(params.row)}>
              <i className="actions-icon-custom">
                <EditBlue className="edit-blue-icon" />
              </i>
            </IconButton>

            <IconButton type="button" onClick={() => handleDelete(params.row)}>
              <i className="actions-icon-custom">
                <DeleteRed className="edit-blue-icon" />
              </i>
            </IconButton>
          </>
        );
      },
    },
  ];

  // PAgination MaNAGEMENT
  const totalItems = investmentData?.total_records;

  // Handle page change
  const handlePageChange = useCallback((page) => {
    setCurrentPage(page);
  }, []);

  // Handle items per page change
  const handleItemsPerPageChange = useCallback((perPage) => {
    setCurrentPage(1); // Reset current page to 1
    setItemsPerPage(perPage);
  }, []);

  return (
    <>
      {investmentData?.data?.length > 0 ? (
        <>
          <div className="d-flex justify-content-between align-items-center mb-3">
            <h4 className="fw-bolder">Investment Preferences</h4>
            {type !== "view" && (
              <CustomButton
                text="Add Investment Preference"
                icon={<Add fill="white" />}
                handleClick={() => {
                  navigate(`/dashboard/edit-company/add-investment-preference`);
                }}
                type="btn-primary"
              />
            )}
          </div>

          <CustomDatagrid
            rows={investmentData?.data || []}
            getRowId={(row) => row.id}
            columns={preferenceColumns}
            height={() => 80}
            tHeight="300px"
            checkRequired={false}
          />

          {totalItems > 10 && (
            <Pagination
              itemsPerPage={itemsPerPage}
              totalItems={totalItems}
              currentPage={currentPage}
              onPageChange={handlePageChange}
              onItemsPerPageChange={handleItemsPerPageChange}
            />
          )}
        </>
      ) : type === "view" ? (
        <NoContentCard
          small={true}
          title="No Preferences Added Yet"
          icon={<Investement />}
        />
      ) : (
        <NoContentCard
          small={true}
          title="No Preferences Added Yet"
          subtitle="To add Investor Preferences, tap the add button."
          icon={<Investement />}
          handleClick={() => {
            navigate(`/dashboard/edit-company/add-investment-preference`);
          }}
          //   color='white'
          tag="Add Investment Preferences"
        />
      )}

      <DeleteModal
        name={modalState.name}
        id={modalState.modalId}
        isActive={modalState.isDeleteModalOpen}
        onClose={() =>
          setModalState((prevState) => ({
            ...prevState,
            isDeleteModalOpen: false,
          }))
        }
        title="Investment Preference"
        onClick={handleDeletion}
      />
    </>
  );
};

export default InvestmentPreference;
