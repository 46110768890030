import dayjs from "dayjs";
import { Field, FieldArray, Formik } from "formik";
import moment from "moment";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { toast } from "react-hot-toast";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { confimationStyles } from "../../../../../../assets/styles/toast";
import { statusData } from "../../../../../../helpers/staticData";
import { setLoading } from "../../../../../../store/slices/loaderSlice";
import Add from "../../../../../Icons/Add";
import CustomButton from "../../../../../atoms/CustomeButton";
import SelectDropdown from "../../../../../atoms/Dropdown";
import Error from "../../../../../atoms/Error";
import InputLayout from "../../../../../atoms/InputLayout";
import { DateSelector } from "../../../../../molecules/MonthYearSelector";
import {
  addPeopleWorkHistory,
  getWorkDetailsById,
  updateWorkDetailsData,
} from "../../peopleService";
import "./styles.scss";

const AddWork = () => {
  const { name, id, people_id } = useParams();
  const [cookies] = useCookies(["t"]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [workDetailsDataId, setWorkDetailsDataId] = useState();
  const [minDate, setMinDate] = useState({
    listing: new Date(),
  });

  const validationSchema = Yup.object().shape({
    addWork: Yup.array().of(
      Yup.object().shape({
        company_name: Yup.string().required("Company Name is required"),
        title: Yup.string().required("Position is required"),
        work_description: Yup.string().notRequired(
          "Work Description is required"
        ),
        // start_date: Yup.date().required("Start Date is required"),
        start_date: Yup.date().when("currently_working_here", {
          is: false,
          then: () => Yup.date().notRequired(),
          otherwise: () => Yup.date().required("Start Date is required"),
        }),
        email: Yup.string()
          .email("Invalid Email Format")
          .when("currently_working_here", {
            is: false,
            then: () =>
              Yup.string().email("Invalid Email Format").notRequired(),
            otherwise: () =>
              Yup.string()
                .email("Invalid Email Format")
                .required("Email is Required"),
          }),
        currently_working_here: Yup.boolean().nullable(),
      })
    ),
  });

  useEffect(() => {
    const getWorkDetailsDataById = async () => {
      try {
        dispatch(setLoading(true));
        const response = await getWorkDetailsById(id);
        dispatch(setLoading(false));
        setWorkDetailsDataId(response);
        setMinDate((prev) => ({
          ...prev,
          listing: moment(response?.start_date).format("YYYY-MM-DD"),
        }));
      } catch (exp) {
        console.log("Error", exp);
      }
    };

    if (!people_id) {
      getWorkDetailsDataById();
    }
  }, [id]);
  const initialValues = {
    addWork: [
      {
        company_name: workDetailsDataId?.company_name || "",
        title: workDetailsDataId?.title || "",
        start_date: workDetailsDataId?.start_date || null,
        end_date: workDetailsDataId?.end_date || null,
        email: workDetailsDataId?.email || "",
        work_description: workDetailsDataId?.description || "",
        status: workDetailsDataId?.status || true,
        currently_working_here:
          workDetailsDataId?.currently_working_here || false,
      },
    ],
  };

  const handleSubmit = async (values) => {
    const date = new Date();
    const peopleId = name;
    const tempPayload = values?.addWork?.map((item) => {
      return (
        item?.currently_working_here === true &&
        (item.end_date = date.toISOString())
      );
    });

    try {
      if (id) {
        values?.addWork?.map((item) => {
          return (item.status =
            item?.status === true
              ? true
              : item?.status == false
              ? false
              : workDetailsDataId?.status == false
              ? false
              : true);
        });
        values.addWork[0].id = id;
        dispatch(setLoading(true));
        const response = await updateWorkDetailsData(
          values?.addWork?.[0],
          cookies.t
        );
        if (response) {
          dispatch(setLoading(false));
          toast.success("Work Updated Successfully", {
            duration: 1000,
            style: confimationStyles,
          });
          navigate(-1);
        }
      } else {
        dispatch(setLoading(true));
        const response = await addPeopleWorkHistory(
          values,
          people_id,
          cookies.t
        );
        if (response) {
          dispatch(setLoading(false));
          toast.success("Work Details Added Successfully", {
            duration: 1000,
            style: confimationStyles,
          });
          navigate(-1);
        }
      }
    } catch (exp) {
      console.log("People Work History", exp);
    }
  };

  return (
    <Formik
      key={Math.random}
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnChange
      validateOnBlur
      enableReinitialize
      onSubmit={handleSubmit}
    >
      {({
        isValid,
        dirty,
        setFieldValue,
        touched,
        errors,
        values,
        handleBlur,
        handleSubmit,
      }) => (
        <div className="work-view">
          <FieldArray name="addWork">
            {({ push, remove }) => (
              <>
                <div className="d-flex justify-content-between">
                  <div className="h1-j mb-3">
                    {id ? "Edit Work Detail" : "Add Work "}{" "}
                  </div>
                  {!id && (
                    <button
                      className="btn btn-primary px-4 mt-0"
                      onClick={() => push(dataObj)}
                    >
                      <i className={`d-flex me-2 `}>
                        <Add />
                      </i>
                      Add Work
                    </button>
                  )}
                </div>
                {values.addWork?.map((preference, index) => (
                  <>
                    {id ? (
                      <>
                        <div className="p-1 pb-4">
                          <form>
                            <div className="row g-3">
                              <InputLayout>
                                <label
                                  htmlFor="companyName"
                                  className="required-field"
                                >
                                  Company Name
                                </label>
                                <Field
                                  type="text"
                                  className={`${
                                    touched?.addWork?.[index].company_name &&
                                    errors?.addWork?.[index]?.company_name
                                      ? "error-input"
                                      : ""
                                  }`}
                                  autoComplete="add-Work"
                                  name={`addWork[${index}].company_name`}
                                  placeholder="Enter Company Name"
                                  onBlur={handleBlur}
                                />
                                {touched?.addWork?.[index].company_name &&
                                  errors?.addWork?.[index]?.company_name && (
                                    <Error
                                      error={
                                        errors?.addWork[index]?.company_name
                                      }
                                    />
                                  )}
                              </InputLayout>
                              <InputLayout>
                                <label
                                  htmlFor="title"
                                  className="required-field"
                                >
                                  Title
                                </label>
                                <Field
                                  type="text"
                                  className={`${
                                    touched?.addWork?.[index].title &&
                                    errors?.addWork?.[index]?.title
                                      ? "error-input"
                                      : ""
                                  }`}
                                  autoComplete="add-Work"
                                  name={`addWork[${index}].title`}
                                  placeholder="Enter Title"
                                  onBlur={handleBlur}
                                />
                                {touched?.addWork?.[index].title &&
                                  errors?.addWork?.[index]?.title && (
                                    <Error
                                      error={errors?.addWork[index]?.title}
                                    />
                                  )}
                              </InputLayout>
                              <InputLayout>
                                <label
                                  htmlFor="startDate"
                                  className={
                                    values?.addWork?.[index]
                                      ?.currently_working_here
                                      ? "required-field"
                                      : ""
                                  }
                                >
                                  Start Date
                                </label>
                                <DateSelector
                                  placeholder="Select Start Date"
                                  className={`${
                                    touched?.addWork?.[index].start_date &&
                                    errors?.addWork?.[index]?.start_date
                                      ? "error-input"
                                      : ""
                                  }`}
                                  onChange={async (option) => {
                                    setFieldValue(
                                      `addWork[${index}].start_date`,
                                      option
                                    );
                                    setMinDate((prev) => ({
                                      ...prev,
                                      listing:
                                        moment(option).format("YYYY-MM-DD"),
                                    }));
                                  }}
                                  disableFuture
                                  selectedDate={
                                    values.addWork[index].start_date
                                  }
                                />
                                {touched?.addWork?.[index].start_date &&
                                  errors?.addWork?.[index]?.start_datea && (
                                    <Error
                                      error={errors?.addWork[index]?.start_date}
                                    />
                                  )}
                              </InputLayout>
                            </div>
                            <div className="row g-3 mt-2 ">
                              <InputLayout>
                                <label>Currently Working in this company</label>
                                <div
                                  className="d-flex align-items-center justify-content-between"
                                  style={{ width: "55%", height: "40px" }}
                                >
                                  <div className="d-flex align-items-center">
                                    <label className="radioContainer">
                                      <input
                                        type="radio"
                                        name="currently_working_here"
                                        value={true}
                                        onChange={() => {
                                          setFieldValue(
                                            `addWork[${index}].currently_working_here`,
                                            true
                                          );
                                        }}
                                        checked={
                                          values.addWork[index]
                                            .currently_working_here === true
                                        }
                                      />

                                      <span className="radio"></span>
                                      <span className="label">Yes</span>
                                    </label>
                                  </div>
                                  <div className="d-flex align-items-center">
                                    <label className="radioContainer">
                                      <input
                                        type="radio"
                                        name="currently_working_here"
                                        value={false}
                                        onChange={() => {
                                          setFieldValue(
                                            `addWork[${index}].currently_working_here`,
                                            false
                                          );
                                        }}
                                        checked={
                                          values.addWork[index]
                                            .currently_working_here === false
                                        }
                                      />

                                      <span className="radio"></span>
                                      <span className="label">No</span>
                                    </label>
                                  </div>
                                </div>
                              </InputLayout>
                              <InputLayout>
                                <label htmlFor="endDate">End Date</label>
                                <DateSelector
                                  placeholder="Select End Date"
                                  // className={`${
                                  //   touched?.addWork?.[index].end_date &&
                                  //   errors?.addWork?.[index]?.end_date
                                  //     ? "error-input"
                                  //     : ""
                                  // }`}
                                  onChange={async (option) => {
                                    setFieldValue(
                                      `addWork[${index}].end_date`,
                                      option
                                    );
                                  }}
                                  disabled={
                                    values.addWork[index].currently_working_here
                                  }
                                  disableFuture
                                  minDateData={dayjs(minDate.listing)}
                                  selectedDate={values.addWork[index].end_date}
                                />
                                {/* {touched?.addWork?.[index].end_date &&
                              errors?.addWork?.[index]?.end_date && (
                                <Error
                                  error={errors?.addWork[index]?.end_date}
                                />
                              )} */}
                              </InputLayout>
                              <InputLayout>
                                <label
                                  htmlFor="email"
                                  className={
                                    values?.addWork?.[index]
                                      ?.currently_working_here
                                      ? "required-field"
                                      : ""
                                  }
                                >
                                  Email
                                </label>
                                <Field
                                  type="text"
                                  className={`${
                                    touched?.addWork?.[index].email &&
                                    errors?.addWork?.[index]?.email
                                      ? "error-input"
                                      : ""
                                  }`}
                                  autoComplete="add-Work-email"
                                  name={`addWork[${index}.email]`}
                                  placeholder="Enter Email"
                                  onBlur={(e) => {
                                    handleBlur(e);
                                  }}
                                />
                                {touched?.addWork?.[index].email &&
                                  errors?.addWork?.[index]?.email && (
                                    <Error
                                      error={errors.addWork[index].email}
                                    />
                                  )}
                              </InputLayout>
                            </div>
                            <div className="row g-2 mt-2">
                              <InputLayout>
                                <label>Description</label>
                                <Field
                                  type="textarea"
                                  component="textarea"
                                  name={`addWork[${index}].work_description`}
                                  placeholder="Enter Description"
                                  cols="20"
                                  rows="3"
                                />
                              </InputLayout>
                              <InputLayout>
                                <label>Status</label>
                                <SelectDropdown
                                  data={statusData}
                                  className={`${
                                    touched?.addWork?.[index].status &&
                                    errors?.addWork?.[index]?.status
                                      ? "error-input"
                                      : ""
                                  }`}
                                  placeholder={"Select Status"}
                                  onChange={async (option) => {
                                    setFieldValue(
                                      `addWork[${index}].status`,
                                      option
                                    );
                                  }}
                                  selectedValue={workDetailsDataId?.status}
                                />
                                {touched?.addWork?.[index].status &&
                                  errors?.addWork?.[index]?.status && (
                                    <Error
                                      error={errors.addWork[index].status}
                                    />
                                  )}
                              </InputLayout>
                            </div>
                          </form>
                        </div>
                      </>
                    ) : (
                      <>
                        <div key={index}>
                          <div
                            className="accordion px-2"
                            id={`accordionExample${index}`}
                          >
                            <div
                              className="accordion-item"
                              style={{ position: "relative" }}
                            >
                              <h2
                                className="accordion-header"
                                id={`heading${index}`}
                                style={{ display: "flex" }}
                              >
                                {!id && (
                                  <>
                                    <div className="d-flex justify-content-between align-items-center">
                                      <h4
                                        className="normal-heading"
                                        style={{
                                          border: "none",
                                          marginBottom: "0",
                                          alignItems: "center",
                                          marginLeft: "25px",
                                          minWidth: "max-content",
                                          fontWeight: "600",
                                          fontSize: "18px",
                                        }}
                                      >
                                        {`Work ${index + 1}`}
                                      </h4>
                                      {index !== 0 && (
                                        <span
                                          className="cursor-pointer error-div work-remove"
                                          style={{}}
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            remove(index);
                                          }}
                                        >
                                          Remove
                                        </span>
                                      )}
                                    </div>
                                    {/* <hr className="mt-0" /> */}
                                  </>
                                )}
                                <button
                                  className={`accordion-button ${
                                    index === 0 ? "" : "collapsed"
                                  }`}
                                  // style={{width:'max-content'}}
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target={`#collapse${index}`}
                                  aria-expanded="false"
                                  aria-controls={`collapse${index}`}
                                ></button>
                              </h2>
                              <div
                                id={`collapse${index}`}
                                className={`accordion-collapse collapse ${
                                  index === 0 ? "show" : ""
                                }`}
                                aria-labelledby={`heading${index}`}
                              >
                                <div className="accordion-body">
                                  <div className="p-1 pb-4">
                                    <form>
                                      <div className="row g-3">
                                        <InputLayout>
                                          <label
                                            htmlFor="companyName"
                                            className="required-field"
                                          >
                                            Company Name
                                          </label>
                                          <Field
                                            type="text"
                                            className={`${
                                              touched?.addWork?.[index]
                                                ?.company_name &&
                                              errors?.addWork?.[index]
                                                ?.company_name
                                                ? "error-input"
                                                : ""
                                            }`}
                                            autoComplete="add-Work"
                                            name={`addWork[${index}].company_name`}
                                            placeholder="Enter Company Name"
                                            onBlur={handleBlur}
                                          />
                                          {touched?.addWork?.[index]
                                            ?.company_name &&
                                            errors?.addWork?.[index]
                                              ?.company_name && (
                                              <Error
                                                error={
                                                  errors?.addWork[index]
                                                    ?.company_name
                                                }
                                              />
                                            )}
                                        </InputLayout>
                                        <InputLayout>
                                          <label
                                            htmlFor="title"
                                            className="required-field"
                                          >
                                            Title
                                          </label>
                                          <Field
                                            type="text"
                                            className={`${
                                              touched?.addWork?.[index]
                                                ?.title &&
                                              errors?.addWork?.[index]?.title
                                                ? "error-input"
                                                : ""
                                            }`}
                                            autoComplete="add-Work"
                                            name={`addWork[${index}].title`}
                                            placeholder="Enter Title"
                                            onBlur={handleBlur}
                                          />
                                          {touched?.addWork?.[index]?.title &&
                                            errors?.addWork?.[index]?.title && (
                                              <Error
                                                error={
                                                  errors?.addWork[index]?.title
                                                }
                                              />
                                            )}
                                        </InputLayout>
                                        <InputLayout>
                                          <label
                                            htmlFor="startDate"
                                            className={
                                              values?.addWork?.[index]
                                                ?.currently_working_here
                                                ? "required-field"
                                                : ""
                                            }
                                          >
                                            Start Date{" "}
                                          </label>
                                          <DateSelector
                                            placeholder="Select Start Date"
                                            className={`${
                                              touched?.addWork?.[index]
                                                ?.start_date &&
                                              errors?.addWork?.[index]
                                                ?.start_date
                                                ? "error-input"
                                                : ""
                                            }`}
                                            onChange={async (option) => {
                                              setFieldValue(
                                                `addWork[${index}].start_date`,
                                                option
                                              );
                                              setMinDate((prev) => ({
                                                ...prev,
                                                listing:
                                                  moment(option).format(
                                                    "YYYY-MM-DD"
                                                  ),
                                              }));
                                            }}
                                            disableFuture
                                            selectedDate={
                                              values.addWork[index]?.start_date
                                            }
                                          />
                                          {touched?.addWork?.[index]
                                            ?.start_date &&
                                            errors?.addWork?.[index]
                                              ?.start_datea && (
                                              <Error
                                                error={
                                                  errors?.addWork[index]
                                                    ?.start_date
                                                }
                                              />
                                            )}
                                        </InputLayout>
                                      </div>
                                      <div className="row g-3 mt-2 ">
                                        <InputLayout>
                                          <label>
                                            Currently Working in this company
                                          </label>
                                          <div
                                            className="d-flex align-items-center justify-content-between"
                                            style={{
                                              width: "55%",
                                              height: "40px",
                                            }}
                                          >
                                            <div className="d-flex align-items-center">
                                              <label className="radioContainer">
                                                <input
                                                  type="radio"
                                                  name="currently_working_here"
                                                  value={true}
                                                  onChange={() => {
                                                    setFieldValue(
                                                      `addWork[${index}].currently_working_here`,
                                                      true
                                                    );
                                                  }}
                                                  checked={
                                                    values.addWork[index]
                                                      .currently_working_here ===
                                                    true
                                                  }
                                                />

                                                <span className="radio"></span>
                                                <span className="label">
                                                  Yes
                                                </span>
                                              </label>
                                            </div>
                                            <div className="d-flex align-items-center">
                                              <label className="radioContainer">
                                                <input
                                                  type="radio"
                                                  name="currently_working_here"
                                                  value={false}
                                                  onChange={() => {
                                                    setFieldValue(
                                                      `addWork[${index}].currently_working_here`,
                                                      false
                                                    );
                                                  }}
                                                  checked={
                                                    values.addWork[index]
                                                      .currently_working_here ===
                                                    false
                                                  }
                                                />

                                                <span className="radio"></span>
                                                <span className="label">
                                                  No
                                                </span>
                                              </label>
                                            </div>
                                          </div>
                                        </InputLayout>
                                        <InputLayout>
                                          <label htmlFor="endDate">
                                            End Date
                                          </label>
                                          <DateSelector
                                            placeholder="Select End Date"
                                            // className={`${
                                            //   touched?.addWork?.[index].end_date &&
                                            //   errors?.addWork?.[index]?.end_date
                                            //     ? "error-input"
                                            //     : ""
                                            // }`}
                                            onChange={async (option) => {
                                              setFieldValue(
                                                `addWork[${index}].end_date`,
                                                option
                                              );
                                            }}
                                            disabled={
                                              values.addWork[index]
                                                ?.currently_working_here
                                            }
                                            disableFuture
                                            minDateData={dayjs(minDate.listing)}
                                            selectedDate={
                                              values.addWork[index]?.end_date
                                            }
                                          />
                                          {/* {touched?.addWork?.[index].end_date &&
                              errors?.addWork?.[index]?.end_date && (
                                <Error
                                  error={errors?.addWork[index]?.end_date}
                                />
                              )} */}
                                        </InputLayout>
                                        <InputLayout>
                                          <label
                                            htmlFor="email"
                                            className={
                                              values?.addWork?.[index]
                                                ?.currently_working_here
                                                ? "required-field"
                                                : ""
                                            }
                                          >
                                            Email
                                          </label>
                                          <Field
                                            type="text"
                                            className={`${
                                              touched?.addWork?.[index]
                                                ?.email &&
                                              errors?.addWork?.[index]?.email
                                                ? "error-input"
                                                : ""
                                            }`}
                                            autoComplete="add-Work-email"
                                            name={`addWork[${index}.email]`}
                                            placeholder="Enter Email"
                                            onBlur={(e) => {
                                              handleBlur(e);
                                            }}
                                          />
                                          {touched?.addWork?.[index]?.email &&
                                            errors?.addWork?.[index]?.email && (
                                              <Error
                                                error={
                                                  errors.addWork[index]?.email
                                                }
                                              />
                                            )}
                                        </InputLayout>
                                      </div>
                                      <div className="row g-2 mt-2">
                                        <InputLayout>
                                          <label>Description</label>
                                          <Field
                                            type="textarea"
                                            component="textarea"
                                            name={`addWork[${index}].work_description`}
                                            placeholder="Enter Description"
                                            cols="20"
                                            rows="3"
                                          />
                                        </InputLayout>
                                        <InputLayout>
                                          <label>Status</label>
                                          <SelectDropdown
                                            data={statusData}
                                            className={`${
                                              touched?.addWork?.[index]
                                                ?.status &&
                                              errors?.addWork?.[index]?.status
                                                ? "error-input"
                                                : ""
                                            }`}
                                            placeholder={"Select Status"}
                                            onChange={async (option) => {
                                              setFieldValue(
                                                `addWork[${index}].status`,
                                                option
                                              );
                                            }}
                                            selectedValue={
                                              workDetailsDataId?.status
                                            }
                                          />
                                          {touched?.addWork?.[index]?.status &&
                                            errors?.addWork?.[index]
                                              ?.status && (
                                              <Error
                                                error={
                                                  errors.addWork[index].status
                                                }
                                              />
                                            )}
                                        </InputLayout>
                                      </div>
                                    </form>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </>
                ))}

                <div className="mt-auto">
                  <footer className="footer">
                    <CustomButton
                      text="Back"
                      handleClick={() => {
                        if (dirty) {
                          const userConfirmed = window.confirm(
                            "You have unsaved changes. Are you sure you want to leave?"
                          );
                          if (!userConfirmed) {
                            return;
                          } else {
                            navigate(-1);
                          }
                        } else {
                          navigate(-1);
                        }
                      }}
                    />
                    {id ? (
                      <button
                        className="btn btn-primary px-4 mt-0"
                        type="submit"
                        disabled={!isValid}
                        onClick={() => {
                          handleSubmit(values);
                        }}
                      >
                        Update
                      </button>
                    ) : (
                      <button
                        className="btn btn-primary px-4 mt-0"
                        type="submit"
                        disabled={!isValid}
                        onClick={() => {
                          handleSubmit(values);
                        }}
                      >
                        Add
                      </button>
                    )}
                  </footer>
                </div>
              </>
            )}
            {/* {console.log("dirty", dirty)}  */}
          </FieldArray>
        </div>
      )}
    </Formik>
  );
};

const dataObj = {
  company_name: "",
  title: "",
  start_date: "",
  end_date: "",
  email: "",
  work_description: "",
  status: "",
  currently_working_here: false,
};

export default AddWork;
